import { Component } from 'react';
import Footer from '../Footer';
import { DatePicker, Skeleton } from "antd";
import 'antd/dist/antd.css';
import TopBar from '../TopBar';
import { CSVLink } from 'react-csv';
import { RRServer } from '../../utils/lib';
import { db } from '../../utils/Firebase';
import { DriverAttempts, DriverData, DriverID, DriverResults, QuizScore, UserType } from '../../utils/types';
import { Link } from 'react-router-dom';
import { Data } from 'react-csv/components/CommonPropTypes';
import { DocumentData } from 'firebase/firestore';


const { RangePicker } = DatePicker;

const csvData = [
    ["Strength", "Weakness"],
    ["Driving with hazard lights on during fog", "Following vehicles in the front closely without maintaing minimum distance"],
    ["Using low beam in fog", "Colliding with vehicles in front"],
    ["Giving way for vehicles driving up the slope", "Colliding on the median"]
];

const headers = [
    // { label: "S.No", key: "sno" },
    { label: "Name", key: "name" },
    { label: "License Number", key: "lno" },
    { label: "Issue Date", key: "issueDate" },
    { label: "License Validity", key: "lValidity" },
    { label: "Blood Type", key: "bType" },
    { label: "Spectactles", key: "specs" },
    { label: "Score", key: "totalScore" },
    { label: "Vision Test Score", key: "vScore" },
    { label: "Color Test Score", key: "cScore" },
    { label: "Rules Quiz Score", key: "Rules.Score" },
];

const normalScoreHeaders = [
    { label: "Hazchem Score", key: "HazChem.Score" },
    { label: "Rain Score", key: "Rain.Score" },
    { label: "Fog Score", key: "Fog.Score" },
    { label: "City Traffic Score", key: "CityTraffic.Score" },
    { label: "Hilly Terrain Score", key: "HillyTerrain.Score" },
];
const specialScoreHeaders = [
    { label: "Rain Score", key: "Rain.Score" },
    { label: "City Traffic Score", key: "CityTraffic.Score" },
    { label: "Outer Ring Road Score", key: "OuterRingRoad.Score" },
];
const apScoreHeaders = [
    { label: "Knowledge Quiz Score", key: "HazChem.Score" },
    { label: "City Level Score", key: "City.Score" },
    { label: "Night Level Score", key: "Level3.Score" }
];

const specialHeaders = [
    { label: "Mobile Number", key: "mobile" },
    { label: "Date of Birth", key: "dob" },
    { label: "Height (cm)", key: "height" },
    { label: "Weight (kg)", key: "weight" },
    { label: "Educational Qualififcation", key: "educationalQualification" },
    { label: "Car Omni Experience (Years)", key: "carOmniExperience" },
    { label: "Maxi Cab Experience (Years)" , key: "maxiCabExperience" },
    { label: "Distance covered in Bus everyday", key: "distanceCoveredInBus" },
    { label: "Average Driving Speed", key: "avgDrivingSpeed" },
    { label: "Organization Name", key: "orgName" },
    { label: "No. of coffees per day", key: "numCoffees" },
    { label: "Wake up Time", key: "wakeUpTime" },
    { label: "Bed Time", key: "bedTime" },
    { label: "Hours of sleep last night", key: "hoursOfSleep" },
    { label: "Pain areas after one shift", key: "painAreasOneShift" },
    { label: "Pain areas after three hours", key: "painAreasThreeHours" },
    { label: "Surgery Parts", key: "surgeryParts" },
    { label: "Other Surgery Parts", key: "otherSurgeryParts" }
]
interface TempDriverData {
    name: string,
    lno: string,
    location: string,
    issueDate: string,
    lValidity: string,
    bType: string,
    specs: string,
    score: string,
    totalScore: string,
    vScore: string,
    cScore: string,
    timeReg: string,
    height?: number,
    weight?: number,
    educationalQualification?: string,
    carOmniExperience?: number,
    maxiCabExperience?: number,
    distanceCoveredInBus?: number,
    avgDrivingSpeed?: number,
    orgName?: string,
    numCoffees?: number,
    wakeUpTime?: string,
    bedTime?: string,
    hoursOfSleep?: number,
    painAreasOneShift?: string,
    painAreasThreeHours?: string,
    surgeryParts?: string,
    otherSurgeryParts?: string,
    mobile?: string,
    dob?: string
}

interface OverallScoresState {
    locations: string[],
    selectedLocation: string,
    data: { displayValue: string }[]
    filteredDetails: TempDriverData[],
    filteredDates: [string, string],
    loading: boolean,
    csvData: any[],
    specialLoc: boolean,
    apLoc: boolean,
    isDownloadable: boolean
}

class OverallScores extends Component<{ logout: () => void, userLocations: string[], userType: UserType, userEmail: string }, OverallScoresState> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: [],
            filteredDetails: [],
            locations: [],
            selectedLocation: '',
            filteredDates: ['', ''],
            loading: true,
            csvData: [],
            specialLoc: false,
            apLoc: false,
            isDownloadable: false
        };
    }

    async componentDidMount() {
        const { userLocations, userEmail } = this.props;

        let special = false;
        let ap = false;
        const userData = await RRServer.getData({
            collection: 'users', db, query: [
                { field: 'email', condition: '==', value: userEmail },
            ]
        });
        let specialLocs = await RRServer.getDataById(db, 'locations', 'special-locations') as DocumentData;
        if (userData.length > 0) {
            special = userData[0].special as boolean;
            ap = userData[0].ap as boolean;
        }

        const locationData = await RRServer.getDataById(db, 'locations', ((special || ap) ? 'special-locations' : 'locations'));
        let locations: string[] = [];

        if (locationData !== undefined) {
            let intermediateLocs = locationData[special ? 'bus-locations' : (ap ? 'ap-locations' : 'locations')] as string[];
            if (!special) {
                intermediateLocs = intermediateLocs.filter((val) => !(specialLocs['bus-locations'] as string[]).includes(val));
            }
            if (!ap) {
                intermediateLocs = intermediateLocs.filter((val) => !(specialLocs['ap-locations'] as string[]).includes(val));
            }

            if (userLocations[0] === 'all') {
                locations = intermediateLocs;
            }
            else {
                locations = (intermediateLocs).filter((val) => userLocations.includes(val));
            }
        }

        const data = locations.map((loc) => {
            return {
                displayValue: loc
            }
        });

        this.setState({ data, locations, loading: false });
    }

    getDriversForLocation = async (location: string, startDate: string, endDate: string) => {
        this.setState({ loading: true, isDownloadable: false });

        let special = false;
        let ap = false;
        const specialLocations = await RRServer.getDataById(db, 'locations', 'special-locations');
        if (specialLocations) {
            const busLocations = specialLocations['bus-locations'] as string[];
            const apLocations = specialLocations['ap-locations'] as string[];
            special = (busLocations.find((val) => val === location) !== undefined);
            ap = (apLocations.find((val) => val === location) !== undefined);
        }

        const driverIdData: DriverID[] = await RRServer.getData({
            db, collection: RRServer.tables.DRIVERID, query: [
                { field: 'Location', condition: '==', value: location },
                { field: 'Date_Registered', condition: '>=', value: (startDate !== '' ? startDate : '0') },
                { field: 'Date_Registered', condition: '<=', value: (endDate !== '' ? endDate : 'z') }
            ], orderBy: 'Date_Registered', direction: 'desc'
        }) as any;

        const licenses: string[] = driverIdData.map((did) => { return did.License });
        const names: string[] = driverIdData.map((did) => { return did.Name });
        const locationsTemp: string[] = driverIdData.map((did) => { return did.Location });
        const timeRegs: string[] = driverIdData.map((did) => { return did.Date_Registered });

        let driverDataTemp: DriverData[] = [];
        let driverResults: DriverResults[] = [];
        let driverAttempts: DriverAttempts[] = [];
        let quizAttempts: QuizScore[] = [];
        const csvData: any[] = [];
        const filteredDetails: TempDriverData[] = [];

        for (let i = 0; i < licenses.length; i += 10) {
            let licenseTemp: string[] = [];
            for (let j = i; j < Math.min((i + 10), licenses.length); j++) {
                licenseTemp.push(licenses[j]);
            }
            const tempData: DriverData[] = await RRServer.getData({
                db, collection: RRServer.tables.DRIVERDATA, query: [
                    { field: 'License', condition: 'in', value: licenseTemp }
                ]
            }) as any;
            driverDataTemp.push(...tempData);

            const tempResults: DriverResults[] = await RRServer.getData({
                db, collection: RRServer.tables.RESULTS, query: [
                    { field: 'License', condition: 'in', value: licenseTemp }
                ]
            }) as any;
            driverResults.push(...tempResults);

            const tempAttempts: DriverAttempts[] = await RRServer.getData({
                db, collection: RRServer.tables.ATTEMPTS, query: [
                    { field: 'License', condition: 'in', value: licenseTemp }
                ]
            }) as any;
            driverAttempts.push(...tempAttempts);

            const tempQuiz: QuizScore[] = await RRServer.getData({
                db, collection: RRServer.tables.QUIZ, query: [
                    { field: 'License', condition: 'in', value: licenseTemp }
                ]
            }) as any;
            quizAttempts.push(...tempQuiz);

            filteredDetails.push(...licenseTemp.map((license, index) => {
                const dData = driverDataTemp.find((val) => val.License === license);
                const rData = driverResults.find((val) => val.License === license);
                const aData = driverAttempts.filter((val) => val.License === license);
                const qData = quizAttempts.filter((val) => val.License === license);
    
                let score = 0;
                let attemptedLevels: string[] = [];
                let attemptedQuiz: string[] = [];
                aData.forEach((a) => {
                    if (attemptedLevels.indexOf(a.Level) === -1) {
                        if (ap) {
                            score += (a.Score * 0.4);
                        }
                        else {
                            score += (a.Score * 0.2);
                        }
                        attemptedLevels.push(a.Level);
                    }
                });
                qData.forEach((q) => {
                    if (attemptedQuiz.indexOf(q.Category) === -1) {
                        if (special && q.Category !== 'HazChem') {
                            score += (q.Score * 2);
                        }
                        else if (!special) {
                            score += q.Score;
                        }
                        attemptedQuiz.push(q.Category);
                    }
                });
    
                let levelsScores: {[key: string]: number} = {};
                if (special) {
                    let cityObjScore = aData.find((val:any) => val.Level === 'CityTraffic')?.Score || 0;
                    let rainObjScore = aData.find((val:any) => val.Level === 'Rain')?.Score || 0;
                    let outerRingScore = aData.find((val: any) => val.Level === 'OuterRingRoad')?.Score || 0;
                    levelsScores = {
                        "Rain.Score": rainObjScore,
                        "CityTraffic.Score": cityObjScore,
                        "OuterRingRoad.Score": outerRingScore
                    };
                }
                else if (ap) {
                    let cityScore = aData.find((val) => val.Level === 'City')?.Score || 0;
                    let nightScore = aData.find((val) => val.Level === 'Level3')?.Score || 0;
                    levelsScores = {
                        'City.Score': cityScore,
                        'Level3.Score': nightScore
                    };
                }
                else {
                    let cityObjScore = aData.find((val:any) => val.Level === 'CityTraffic')?.Score || 0;
                    let fogObjScore = aData.find((val:any) => val.Level === 'Fog')?.Score || 0;
                    let rainObjScore = aData.find((val:any) => val.Level === 'Rain')?.Score || 0;
                    let hillyObjScore = aData.find((val:any) => val.Level === 'HillyTerrain')?.Score || 0;
                    levelsScores = {
                        'Rain.Score': rainObjScore,
                        'Fog.Score': fogObjScore,
                        'HillyTerrain.Score': hillyObjScore,
                        'CityTraffic.Score': cityObjScore,    
                    }
                }
                let rulesScore = qData.find((val:any) => val.Category === 'Rules')?.Score || 0;
                let hazScore = qData.find((val:any) => val.Category === 'HazChem')?.Score || 0;
    
                const tempFilteredDetail: TempDriverData = {
                    totalScore: Math.round(score).toString(),
                    name: names[i + index],
                    lno: license,
                    location: locationsTemp[i + index],
                    issueDate: dData?.LicenseIssueDate || '',
                    lValidity: dData?.LicenseExpiry || '',
                    bType: dData?.BloodType || '',
                    specs: dData?.Spectacles ? 'Yes' : 'No',
                    score: score.toString().split('.')[0] + '%',
                    vScore: (((rData?.VisionResult  || 0)).toString() + '%') || '',
                    cScore: ((rData?.ColourResult || 0).toString() + '%') || '',
                    timeReg: timeRegs[i + index],
                    avgDrivingSpeed: dData?.AvgDrivingSpeed,
                    bedTime: dData?.BedTime,
                    carOmniExperience: dData?.CarOmniExperienceYears,
                    distanceCoveredInBus: dData?.DistanceCoveredInBusEveryday,
                    educationalQualification: dData?.EducationalQualification,
                    height: dData?.Height_cm,
                    hoursOfSleep: dData?.HoursOfSleepLastNight,
                    maxiCabExperience: dData?.MaxiCabBusExperienceYears,
                    numCoffees: dData?.NoOfCoffeesInADay,
                    orgName: dData?.OrganizationName,
                    otherSurgeryParts: dData?.OtherSurgeryParts,
                    painAreasOneShift: dData?.PainAreasAfterOneShift,
                    painAreasThreeHours: dData?.PainAreasAfterThreeHours,
                    surgeryParts: dData?.SurgeryParts,
                    wakeUpTime: dData?.WakeupTime,
                    weight: dData?.Weight_kg,
                    mobile: dData?.Mobile,
                    dob: dData?.DateOfBirth
                };
                
                csvData.push({
                    ...tempFilteredDetail,
                    'Rules.Score': rulesScore,
                    'HazChem.Score': hazScore,
                    ...levelsScores
                });
    
                return tempFilteredDetail;
            }));

            driverDataTemp = [];
            driverAttempts = [];
            driverResults = [];
            quizAttempts = [];
    
            this.setState({ filteredDetails, csvData, loading: false, specialLoc: special, apLoc: ap });
        }

        this.setState({ isDownloadable: true }, () => console.log('downloadable'));
    }

    handleOnchange = async () => {
        const { selectedLocation, filteredDates } = this.state;
        const startDate = filteredDates[0];
        const endDate = filteredDates[1];
        await this.getDriversForLocation(selectedLocation, startDate, endDate);
    }

    getCSVData = (): Data => {
        return this.state.filteredDetails.map((fd, index) => {
            return [
                index, fd.name, fd.lno, fd.timeReg, fd.issueDate
            ];
        });
    }

    render() {
        const { userType } = this.props;
        const { csvData, loading, filteredDetails, selectedLocation, data, specialLoc, isDownloadable, apLoc } = this.state;

        return (
            <>
                <TopBar title="Dashboard" logout={this.props.logout} />

                <div className='welcomeCard'>
                    <div className="info">
                        <p>Welcome User</p>
                    </div>
                    <div className="image">
                        {/* <img src="https://download.logo.wine/logo/Indian_Oil_Corporation/Indian_Oil_Corporation-Logo.wine.png" alt="WelcomeCard Image" /> */}
                    </div>
                </div>

                <div className='contentContainer dash'>

                    <div className="overallTableReport" style={{ padding: "5px" }}>

                        <div className="filter">
                            {
                                <div className="locationFilter">
                                    <form>
                                        <select name="loc" className='form-control' id="myInput" value={selectedLocation} onChange={(e) => { this.setState({ selectedLocation: e.target.value }, this.handleOnchange); }} >
                                            <option>--Select Location--</option>
                                            {data.map((item, key) =>
                                                <option value={item.displayValue} key={key}>{item.displayValue}</option>
                                            )}
                                        </select>
                                        <button type="reset" className='clearInput'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                            </svg>
                                        </button>
                                    </form>
                                </div>
                            }
                            <div className="dateRangeFilter" style={{ width: userType === UserType.SUPER_ADMIN ? 'auto' : '100%' }}>
                                <RangePicker style={{ marginLeft: "10px" }} format={"DD-MM-YYYY"} onChange={(date, dateString) => { this.setState({ filteredDates: dateString }, this.handleOnchange); }} />
                            </div>
                        </div>

                        <Skeleton active={true} loading={loading}>
                            {
                                isDownloadable
                                &&
                                <CSVLink className='csvButton btn btn-primary' data={csvData} filename="overallScores.csv" headers={specialLoc ? [...headers, ...specialScoreHeaders, ...specialHeaders] : (apLoc ? [...headers, ...apScoreHeaders] : [...headers, ...normalScoreHeaders])}>Export CSV</CSVLink>
                            }
                            <table className='table table-bordered table-hover'>
                                <thead>
                                    <tr>
                                        <th>S. No</th>
                                        <th>Name</th>
                                        <th>License Number</th>
                                        <th>Issue Date</th>
                                        <th>License Validity</th>
                                        <th>Blood Type</th>
                                        <th>Spectactles</th>
                                        <th>Score</th>
                                        <th>Vision Test Score</th>
                                        <th>Color Test Score</th>
                                        {
                                            specialLoc
                                            &&
                                            specialHeaders.map((sh) => <th key={'sh_' + sh.key}>{sh.label}</th>)
                                        }
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredDetails.map((tableData: TempDriverData, index: number) => {
                                        return <tr key={index}>
                                            <th>{index + 1}</th>
                                            <th>{tableData.name}</th>
                                            <th>{tableData.lno}</th>
                                            <th>{tableData.issueDate}</th>
                                            <th>{tableData.lValidity}</th>
                                            <th>{tableData.bType}</th>
                                            <th>{tableData.specs}</th>
                                            <th>
                                                {/* {parseInt(tableData.score)} */}
                                                {parseInt(tableData.totalScore)} %
                                            </th>
                                            <th>{tableData.vScore}</th>
                                            <th>{tableData.cScore}</th>
                                            {
                                                specialLoc
                                                &&
                                                specialHeaders.map((sh) => <th key={'shv_' + sh.key}>{(tableData as any)[sh.key]}</th>)
                                            }
                                            <th>
                                                <Link to={'/performanceAnalysis/' + tableData.location + '/' + tableData.lno} className='btn btn-primary' style={{ borderRadius: "0px" }}>View</Link>
                                            </th>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </Skeleton>
                    </div>
                    <Footer />
                </div>

            </>
        );
    }
}

export default OverallScores;