import React from "react";

interface ClassSchedulesListStateProps {
  isToggle: boolean
}

class SAndW extends React.Component<{ strengths: string[], weaknesses: string[] }, ClassSchedulesListStateProps> {
  requesting: { [key: string]: boolean } = {};
  constructor(props: any) {
    super(props);
    this.state = {
      isToggle: true
    };
  }

  toggleCard = () => {
    this.setState({ isToggle: !this.state.isToggle });
  }

  render() {
    const { strengths, weaknesses } = this.props;

    function GetFormattedDate() {
      var todayTime = new Date();
      var month = todayTime.getMonth() + 1;
      var monthFormat = month < 10 ? `0${month}` : month
      var day = todayTime.getDate();
      var dayFormat = day < 10 ? `0${day}` : day
      var year = todayTime.getFullYear();
      return monthFormat + "/" + dayFormat + "/" + year;
    }

    return (
      <>
        <div className={this.state.isToggle ? `dashBotCard1 liveClasses show` : `dashBotCard1 liveClasses hide`} onClick={() => { this.toggleCard() }} style={{width:"75%",margin:"auto"}}>
          <div className="linkTo">
            <div className="titleTag">
              <h4 style={{backgroundColor:"#651efc"}}>Strength and Weakness</h4>
              <div className="arrow" style={{borderLeft:"25px solid #651efc"}}></div>
            </div>

            <span className="">{GetFormattedDate()}</span>
          </div>
          <div className="tableA" style={{borderBottomColor:"#651efc"}}>

            <table className="table table-borderless">
              <thead>
                <tr>
                  <th style={{color:"#651efc"}}>Strength</th>
                  <th style={{color:"#651efc"}}>Weakness</th>
                </tr>
              </thead>
              <tbody>
                {
                  strengths.length >= weaknesses.length
                  &&
                  strengths.map((str, index) => 
                    <tr key={index}>
                      <td>{str}</td>
                      <td>{ (index < weaknesses.length) ? weaknesses[index] : '' }</td>
                    </tr>
                  )
                }
                {
                  strengths.length < weaknesses.length
                  &&
                  weaknesses.map((weak, index) =>
                    <tr key={index}>
                      <td>{ (index < strengths.length) ? strengths[index] : '' }</td>
                      <td>{weak}</td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default SAndW;
