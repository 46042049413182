import { Component } from 'react';
import Footer from '../Footer';
import TopBar from '../TopBar';
import { ReactComponent as Road } from '../../assets/roadSVGs/road.svg';
import { ReactComponent as Rain } from '../../assets/roadSVGs/rain.svg';
import { ReactComponent as Fog } from '../../assets/roadSVGs/fog.svg';
import { ReactComponent as HillyTerrain } from '../../assets/roadSVGs/hillyTerrain.svg';
import { ReactComponent as HazchemQuiz } from '../../assets/roadSVGs/hazchemQuiz.svg';
import { ReactComponent as RulesQuiz } from '../../assets/roadSVGs/rulesQuiz.svg';
import { ReactComponent as CityTraffic } from '../../assets/roadSVGs/cityTraffic.svg';
import { ReactComponent as AverageScore } from '../../assets/roadSVGs/averageScore.svg';
import SAndW from './MiniTableCards/SAndW';
import { DriverAttempts, QuizScore } from '../../utils/types';
import { RRServer } from '../../utils/lib';
import { db } from '../../utils/Firebase';
import rules from '../../utils/rules.json';

interface PerformanceAnalysisScore {
    score: string,
    rainScore: string,
    fogScore?: string,
    cityTrafficScore: string,
    hillyTerrainScore?: string,
    outerRingScore?: string,
    rulesQuizScore: string,
    hazchemQuizScore?: string,
    strengths: string[],
    weaknesses: string[]
}

interface ScoreState {
    performanceScore: PerformanceAnalysisScore | undefined,
    special: boolean,
    ap: boolean
}
class PerformanceAnalysis extends Component<{ logout: () => void }, ScoreState> {
    state: ScoreState = {
        performanceScore: undefined, special: false, ap: false
    }

    async componentDidMount() {
        const id = window.location.href.split('/').reverse()[0];
        const location = window.location.href.split('/').reverse()[1].replaceAll('%20', ' ');

        let special = false;
        let ap = false;
        const specialLocations = await RRServer.getDataById(db, 'locations', 'special-locations');
        if (specialLocations) {
            const busLocations = specialLocations['bus-locations'] as string[];
            special = (busLocations.find((val) => val === location) !== undefined);
            ap = ((specialLocations['ap-locations'] as string[]).find((val) => val === location) !== undefined);
        }

        const driverAttempts: DriverAttempts[] = await RRServer.getData({
            db, collection: RRServer.tables.ATTEMPTS, query: [
                { field: 'License', condition: '==', value: id }
            ]
        }) as any;
        const quizAttempts: QuizScore[] = await RRServer.getData({
            db, collection: RRServer.tables.QUIZ, query: [
                { field: 'License', condition: '==', value: id }
            ]
        }) as any;

        let levelsScores = 0;
        const strengths: string[] = [];
        const weaknesses: string[] = [];

        const positiveList: number[] = [];
        const mistakeList: number[] = [];
        const encounteredList: number[] = [];

        for (const rule of rules.rules) {
            positiveList.push(0);
            mistakeList.push(0);
            encounteredList.push(0);
        }

        let cityScore: number = 0;
        let rainScore: number = 0;
        let hillyScore: number | undefined = undefined;
        let fogScore: number | undefined = undefined;
        let outerScore: number | undefined = undefined;
        if (special) {
            const cityObj = driverAttempts.find((val) => val.Level === 'CityTraffic');
            const outerRingScore = driverAttempts.find((val) => val.Level === 'OuterRingRoad');
            const rainObj = driverAttempts.find((val) => val.Level === 'Rain');

            cityScore = cityObj?.Score || 0;
            outerScore = outerRingScore?.Score || 0;
            rainScore = rainObj?.Score || 0;

            levelsScores = (0.2 * (cityScore)) + (0.2 * (rainScore)) + (0.2 * (outerScore));

            for (const s of (cityObj?.Mistakes || '').split(',')) {
                mistakeList[parseInt(s)] += 1;
            }
            for (const s of (rainObj?.Mistakes || '').split(',')) {
                mistakeList[parseInt(s)] += 1;
            }
            for (const s of (outerRingScore?.Mistakes || '').split(',')) {
                mistakeList[parseInt(s)] += 1;
            }

            for (const s of (cityObj?.Encountered || '').split(',')) {
                encounteredList[parseInt(s)] += 1;
            }
            for (const s of (rainObj?.Encountered || '').split(',')) {
                encounteredList[parseInt(s)] += 1;
            }
            for (const s of (outerRingScore?.Encountered || '').split(',')) {
                encounteredList[parseInt(s)] += 1;
            }
        }
        else if (ap) {
            const cityObj = driverAttempts.find((val) => val.Level === 'City');
            const nightObj = driverAttempts.find((val) => val.Level === 'Level3');

            cityScore = cityObj?.Score || 0;
            fogScore = nightObj?.Score || 0;

            levelsScores = (0.4 * cityScore) + (0.4 * fogScore);

            for (const s of (cityObj?.Mistakes || '').split(',')) {
                mistakeList[parseInt(s)] += 1;
            }
            for (const s of (nightObj?.Mistakes || '').split(',')) {
                mistakeList[parseInt(s)] += 1;
            }

            for (const s of (cityObj?.Encountered || '').split(',')) {
                encounteredList[parseInt(s)] += 1;
            }
            for (const s of (nightObj?.Encountered || '').split(',')) {
                encounteredList[parseInt(s)] += 1;
            }
        }
        else {
            const cityObj = driverAttempts.find((val) => val.Level === 'CityTraffic');
            const fogObj = driverAttempts.find((val) => val.Level === 'Fog');
            const rainObj = driverAttempts.find((val) => val.Level === 'Rain');
            const hillyObj = driverAttempts.find((val) => val.Level === 'HillyTerrain');

            cityScore = cityObj?.Score || 0;
            fogScore = fogObj?.Score || 0;
            rainScore = rainObj?.Score || 0;
            hillyScore = hillyObj?.Score || 0;

            levelsScores = (0.2 * (cityScore)) + (0.2 * (rainScore)) + (0.2 * (fogScore)) + (0.2 * (hillyScore));

            for (const s of (cityObj?.Mistakes || '').split(',')) {
                mistakeList[parseInt(s)] += 1;
            }
            for (const s of (rainObj?.Mistakes || '').split(',')) {
                mistakeList[parseInt(s)] += 1;
            }
            for (const s of (hillyObj?.Mistakes || '').split(',')) {
                mistakeList[parseInt(s)] += 1;
            }
            for (const s of (fogObj?.Mistakes || '').split(',')) {
                mistakeList[parseInt(s)] += 1;
            }

            for (const s of (cityObj?.Encountered || '').split(',')) {
                encounteredList[parseInt(s)] += 1;
            }
            for (const s of (rainObj?.Encountered || '').split(',')) {
                encounteredList[parseInt(s)] += 1;
            }
            for (const s of (hillyObj?.Encountered || '').split(',')) {
                encounteredList[parseInt(s)] += 1;
            }
            for (const s of (fogObj?.Encountered || '').split(',')) {
                encounteredList[parseInt(s)] += 1;
            }
        }

        const rulesScore = quizAttempts.find((val) => val.Category === 'Rules')?.Score || 0;
        const hazScore = quizAttempts.find((val) => val.Category === 'HazChem')?.Score || 0;

        const totalScore = levelsScores + (special ? (rulesScore * 2) : (rulesScore + hazScore));

        for (let i = 0; i < mistakeList.length; i++) {
            if (mistakeList[i] === 0 && rules.rules[i].positive !== 'none') {
                positiveList[i] = encounteredList[i];
            }
        }

        for (let j = 0; j < mistakeList.length; j++) {
            if (positiveList[j] > 0) {
                strengths.push(rules.rules[j].positive);
            }
            else if (mistakeList[j] > 0) {
                weaknesses.push(rules.rules[j].negative);
            }
        }

        if (mistakeList[2] > 0) {
            weaknesses.push('Not maintaining extra safe distance in fog/rain');
        }
        else {
            strengths.push('Maintaining extra safe distance in fog/rain');
        }

        const performanceScore: PerformanceAnalysisScore = {
            cityTrafficScore: (cityScore.toString().split('.')[0] + '%'),
            fogScore: fogScore ? (fogScore.toString().split('.')[0] + '%') : undefined,
            hazchemQuizScore: (hazScore * 10).toString().split('.')[0] + '%',
            hillyTerrainScore: hillyScore ? (hillyScore.toString().split('.')[0] + '%') : undefined,
            outerRingScore: outerScore ? (outerScore.toString().split('.')[0] + '%') : undefined,
            rainScore: rainScore.toString().split('.')[0] + '%',
            rulesQuizScore: (rulesScore * 10).toString().split('.')[0] + '%',
            score: totalScore.toString().split('.')[0] + '%',
            strengths,
            weaknesses
        };

        this.setState({ performanceScore, special, ap });
    }

    render() {
        const { performanceScore, special, ap } = this.state;


        return (
            <>
                <TopBar title="Dashboard" logout={this.props.logout} />

                <div className='welcomeCard'>
                    <div className="info">
                        {/* <h1>{`Hello, ${this.state?.currentLoggedInSchool?.drivingSchoolName}!`}</h1> */}
                        <p>Welcome User</p>
                    </div>
                    <div className="image">
                        {/* <img src="https://download.logo.wine/logo/Indian_Oil_Corporation/Indian_Oil_Corporation-Logo.wine.png" alt="WelcomeCard Image" /> */}
                    </div>
                </div>

                <div className='contentContainer dash'>

                    <div className="performance">
                        <Road className='road' />
                        {
                            !ap
                            &&
                            <>
                            <Rain className='rain' />
                            <span className="rainScore">Rain /{(performanceScore?.rainScore)}</span>
                            </>
                        }
                        <Fog className='fog' />
                        <span className="fogScore">{special ? `Outer Ring Road / ${performanceScore?.outerRingScore}` : (ap ? `Night Level / ${performanceScore?.fogScore}` : `Fog / ${performanceScore?.fogScore}`)}</span>
                        <CityTraffic className='cityTraffic' />
                        <span className="cityTrafficScore">{`City Traffic / ${performanceScore?.cityTrafficScore}`}</span>
                        {
                            !special && !ap
                            &&
                            <>
                                <HillyTerrain className='hillyTerrain' />
                                <span className="hillyTerrainScore"> {special ? '' : `Hilly Terrain / ${performanceScore?.hillyTerrainScore}`}</span>
                            </>
                        }
                        <RulesQuiz className='rulesQuiz' />
                        <span className="rulesQuizScore">Rules Quiz / {performanceScore?.rulesQuizScore}</span>
                        {
                            !special
                            &&
                            <>
                                <HazchemQuiz className='hazchemQuiz' />
                                <span className="hazchemQuizScore"> {ap ? `Knowledge Quiz / ${performanceScore?.hazchemQuizScore}` : `Hazchem Quiz / ${performanceScore?.hazchemQuizScore}`}</span>
                            </>
                        }
                        <AverageScore className='averageScore' />
                        <span className="averageScoreTxt">Average Score /{performanceScore?.score}</span>

                    </div>

                    <div className="dashBot">
                        {
                            (performanceScore?.strengths || []).length > 0 && (performanceScore?.weaknesses || []).length > 0
                            &&
                            <SAndW strengths={performanceScore?.strengths || []} weaknesses={performanceScore?.weaknesses || []} />
                        }
                    </div>
                    <Footer />
                </div>
            </>
        );
    }
}

export default PerformanceAnalysis;