import React from "react";

interface TFState {
  isToggle: boolean
}

interface TFProps {
  topFive: { no: number, name: string, score: number }[]
}

class TopFive extends React.Component<TFProps, TFState> {
  requesting: { [key: string]: boolean } = {};
  constructor(props: any) {
    super(props);
    this.state = {
      isToggle: false
    };
  }
  
  toggleCard = () => {
    this.setState({ isToggle: !this.state.isToggle });
  }

  render() {
    const { topFive } = this.props;

    function GetFormattedDate() {
      var todayTime = new Date();
      var month = todayTime.getMonth() + 1;
      var monthFormat = month < 10 ? `0${month}` : month
      var day = todayTime.getDate();
      var dayFormat = day < 10 ? `0${day}` : day
      var year = todayTime.getFullYear();
      return monthFormat + "/" + dayFormat + "/" + year;
    }

    return (
      <>
        <div className={this.state.isToggle ? `dashBotCard1 liveClasses show` : `dashBotCard1 liveClasses hide`} onClick={() => { this.toggleCard() }}>
          <div className="linkTo">
            <div className="titleTag">
              <h4>Top Five</h4>
              <div className="arrow"></div>
            </div>

            <span className="">{GetFormattedDate()}</span>
          </div>
          <div className="tableA">

            <table className="table table-borderless">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {topFive.map((td: any, index) => {
                  if (index <= 4) {
                    return <tr key={td.no}>
                      <td>{td.no}</td>
                      <td>{td.name}</td>
                    </tr>
                  }
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default TopFive;
