import React from "react";

interface ClassSchedulesListStateProps {
  isToggle: boolean
}

class OverallScoresMini extends React.Component<{ scores: { no: number, name: string, score: number }[] }, ClassSchedulesListStateProps> {
  requesting: { [key: string]: boolean } = {};
  constructor(props: any) {
    super(props);
    this.state = {
      isToggle: false
    };
  }
  toggleCard = () => {
    this.setState({ isToggle: !this.state.isToggle });
  }


  render() {

    function GetFormattedDate() {
      var todayTime = new Date();
      var month = todayTime.getMonth() + 1;
      var monthFormat = month < 10 ? `0${month}` : month;
      var day = todayTime.getDate();
      var dayFormat = day < 10 ? `0${day}` : day;
      var year = todayTime.getFullYear();
      return monthFormat + "/" + dayFormat + "/" + year;
    }

    return (
      <div className={this.state.isToggle ? `dashBotCard1 liveClasses show` : `dashBotCard1 liveClasses hide`} onClick={() => { this.toggleCard() }}>
        <div className="linkTo">
          <div className="titleTag">
            <h4 style={{backgroundColor:"#cc2037"}}>Overall Best Performance</h4>
            <div className="arrow" style={{borderLeft:"25px solid #cc2037"}}></div>
          </div>

          <span className="">{GetFormattedDate()}</span>
        </div>
        <div className="tableA" style={{borderBottomColor:"#cc2037"}}>

          <table className="table table-borderless">
            <thead>
              <tr>
                <th style={{color:"#cc2037"}}>S.No</th>
                <th style={{color:"#cc2037"}}>Name</th>
                <th style={{color:"#cc2037"}}>Score</th>
              </tr>
            </thead>
            <tbody>
              {this.props.scores.map((td: any, index) => {
                if (index <= 4) {
                  return <tr key={td.no}>
                    <td>{td.no}</td>
                    <td>{td.name}</td>
                    <td>{Math.round(td.score)}</td>
                  </tr>
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default OverallScoresMini;
