export enum UserType {
    SUPER_ADMIN, ADMIN, NONE
}

export interface SummaryData {
    location: string,
    numDrivers: number,
    averageScore: number,
    topFive: {no: number, name: string, score: number}[]
}

export interface DriverID {
    Date_Registered: string,
    Language: string,
    License: string,
    Location: string,
    Mobile: string,
    Name: string,
    Time_Registered: string
}

export interface DriverAttempts {
    Completion: number,
    DateStamp: string,
    Encountered: string,
    EthicsNormalized: string,
    Level: string,
    License: string,
    Mistakes: string,
    RulesNormalized: number,
    Score: number,
    TimeStamp: string
}

export interface QuizScore {
    Category: string,
    DateStamp: string,
    License: string,
    Score: number,
    TimeStamp: string
}

export interface DriverData {
    BloodType: string,
    DateOfBirth: string,
    License: string,
    LicenseExpiry: string,
    LicenseIssueDate: string,
    MaritalStatus: string,
    Mobile: string,
    NameWritten: string,
    ReadWrite: number,
    Spectacles: number,
    VerifiedData: number,
    AvgDrivingSpeed?: number,
    Height_cm?: number,
    Weight_kg?: number,
    EducationalQualification?: string,
    CarOmniExperienceYears?: number,
    MaxiCabBusExperienceYears?: number,
    DistanceCoveredInBusEveryday?: number,
    OrganizationName?: string,
    NoOfCoffeesInADay?: number,
    WakeupTime?: string,
    BedTime?: string,
    HoursOfSleepLastNight?: number,
    PainAreasAfterOneShift?: string,
    PainAreasAfterThreeHours?: string,
    SurgeryParts?: string,
    OtherSurgeryParts?: string
}

export interface DriverResults {
    BreathalyzerResult: string,
    ColourResult: number,
    License: string,
    VisionResult: number
}