import React from "react";
import { ToastContainer } from "react-toastify";
import login from "../../assets/loginImage.png";
import logo from "../../assets/RRlogo.png";
import "./RakshakLogin.css";
import 'react-toastify/dist/ReactToastify.css';
import { NavigateFunction, useNavigate } from "react-router-dom";


interface loginState {
  values: {
    code: string;
    password: string;
  };
  isPassword: boolean
}

class Login extends React.Component<{ login: (username: string, password: string) => Promise<boolean>, navigate: NavigateFunction }, loginState> {
  state: loginState = {
    values: {
      code: "",
      password: "",
    },
    isPassword: false
  };

  showPassword = (e: any) => {
    this.setState({ isPassword: !this.state.isPassword })
    e.target.previousSibling.type === 'text' ? e.target.previousSibling.setAttribute("type", "password") : e.target.previousSibling.setAttribute("type", "text");
  }

  render() {
    const onFinish = async (e: any, values: any) => {
      e.preventDefault();
      const result = await this.props.login(values.code, values.password);
      if (result) {
        this.props.navigate('/dashboard');
      }
    };

    return (
      <>
        <div
          className="container-login d-flex justify-content-between"
          style={{ margin: "0" }}
        >
          <div
            className="img d-flex justify-content-around"
            style={{
              // backgroundColor: "#2f2e2e",
              width: "50%",
            }}
          >
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            {/* Same as */}
            <ToastContainer />
            <img src={login} />
          </div>
          <div
            className="login-container"
            style={{
              backgroundColor: "#ffffff",
              padding: "30px",
              width: "50%",
            }}
          >
            <form style={{ margin: "auto" }}>
              <img className="avator" src={logo} />
              <h2>Login</h2>
              <div className="input-div">
                <div className="i">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-people-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    <path
                      fillRule="evenodd"
                      d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                    />
                    <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                  </svg>
                </div>
                <div>
                  <input
                    className="input"
                    placeholder="Username"
                    name="code"
                    value={this.state.values.code}
                    onChange={(e) =>
                      this.setState({
                        values: { ...this.state.values, code: e.target.value },
                      })
                    }
                    type="text"
                  />
                </div>
              </div>
              <div className="input-div">
                <div className="i">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-shield-lock-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159 7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0 0-2.887-.87C9.843.266 8.69 0 8 0zm0 5a1.5 1.5 0 0 1 .5 2.915l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99A1.5 1.5 0 0 1 8 5z"
                    />
                  </svg>
                </div>
                <div>
                  <input
                    className="input"
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={this.state.values.password}
                    onChange={(e) =>
                      this.setState({
                        values: {
                          ...this.state.values,
                          password: e.target.value,
                        },
                      })
                    }
                  />
                  <svg onClick={(e: any) => this.showPassword(e)} style={{ cursor: "pointer", fontSize: "22px", position: "absolute", top: "50%", transform: "translateY(-50%)", right: "30px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                    {this.state.isPassword ?
                      <>
                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                      </>
                      :

                      <>
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                      </>
                    }
                  </svg>
                </div>
              </div>
              <a style={{ opacity: "0", color: "red" }} id="CredentialText">
                Please enter the right credential
              </a>
              <input
                type="button"
                onClick={(e: any) => onFinish(e, this.state.values)}
                className="btn-login"
                value="Login"
              />
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default function (props: any) {
  return <Login {...props} navigate={useNavigate()} />
}
