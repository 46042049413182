// import { Badge } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UserType } from '../utils/types';
import { RRServer } from '../utils/lib';
import { User } from 'firebase/auth';
import { auth, db } from '../utils/Firebase';

interface TopBarProps {
    title: string,
    logout: () => void,
}
const path = window.location.href;
// alert(path.endsWith('/'));
class TopBar extends Component<TopBarProps, { user: User | null, userType: UserType, userLocations: string[], i: number }> {

    constructor(props: any) {
        super(props);

        this.state = { user: null, userType: UserType.NONE, userLocations: [], i: 0 };
    }
    componentDidMount() {
        auth.onAuthStateChanged(async (user) => {
            const email = user?.email || '';
            const userData = await RRServer.getData({
                db, collection: 'users', query: [{ field: 'email', condition: '==', value: email }]
            });


            const userType = await userData[0].type as UserType;
            const userEmail = userData[0].email as any;
            const userLocations = userData[0].locations as string[];

            this.setState({ user, userType: userType, userLocations });

        });
    }

    render() {
        const { userType } = this.state;

        return (
            <div className='topBar'>
                <h3>{}</h3>
                <div className={`${path.endsWith('/') ? 'toolSpace dash' : 'toolSpace'}`}>
                    <section>

                        <div className="dropdown">
                            <button className="dropbtn">
                                {/* <Badge count={2} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-bell" viewBox="0 0 16 16">
                                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                                    </svg>
                                </Badge> */}
                            </button>
                            <div className="dropdown-content two">
                                <a href="#">Notification One</a>
                                <a href="#">Notification Two</a>
                            </div>
                        </div>
                        <div className="dropdown">
                            <button className="dropbtn">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-gear" viewBox="0 0 16 16">
                                    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                                    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                                </svg>
                            </button>
                            <div className="dropdown-content">
                                <Link to="/profile">My Profile</Link>
                                {userType === 0 && <Link to="/addUser">Add User</Link>}
                                {userType === 0 && <Link to="/edit-locations">Edit Locations</Link>}
                                {userType === 0 && <Link to="/edit-drivers">Edit Drivers</Link>}
                                <Link to="/" onClick={this.props.logout}>Logout</Link>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default TopBar;