import { Component } from 'react';
import { db } from '../../utils/Firebase';
import { DriverAttempts, DriverID, QuizScore, SummaryData } from '../../utils/types';
import Footer from '../Footer';
import TopBar from '../TopBar';
import TopFive from './MiniTableCards/TopFive';
import { formatDate, RRServer } from '../../utils/lib';
import Skeleton from 'antd/lib/skeleton/Skeleton';

class Dashboard extends Component<{ logout: () => void, userLocations: any }, { summary: SummaryData[] }> {

    constructor(props: any) {
        super(props);

        this.state = {
            summary: []
        };
    }

    async componentDidMount() {
        const data: DriverID[] = await RRServer.getData({
            db, collection: RRServer.tables.DRIVERID, query: [
                { field: 'Date_Registered', condition: '==', value: formatDate(new Date()) }
            ]
        }) as any;
        const tempSummaryData: TempLicenseData[] = [];
        for (const d of data) {
            const index = tempSummaryData.findIndex((val) => val.location === d.Location);
            if (index === -1) {
                tempSummaryData.push({ location: d.Location, licenses: [d.License], names: [d.Name] });
            }
            else {
                let tempData = tempSummaryData[index];
                tempData.licenses.push(d.License);
                tempData.names.push(d.Name);
                tempSummaryData[index] = tempData;
            }
        }

        const summaryPromise: Promise<SummaryData>[] = tempSummaryData.map(async (value) => {
            let averageScore: number = 0;
            let numDrivers: number = value.licenses.length;
            let topFive: { no: number, name: string, score: number }[] = [];

            const driverPerformances: DriverAttempts[] = [];
            const quizPerformances: QuizScore[] = [];

            const specialLocsDoc = await RRServer.getDataById(db, 'locations', 'special-locations');
            let specialLocs: string[] = [];
            if (specialLocsDoc) {
                specialLocs = specialLocsDoc['bus-locations'];
            }
            const special = specialLocs.includes(value.location);

            for (let i = 0; i < value.licenses.length; i += 10) {
                const licenseTemp: string[] = [];
                for (let j = i; j < Math.min((i + 10), value.licenses.length); j++) {
                    licenseTemp.push(value.licenses[j]);
                }
                
                const tempPerformance: DriverAttempts[] = await RRServer.getData({
                    db, collection: RRServer.tables.ATTEMPTS, query: [
                        { field: 'DateStamp', condition: '==', value: formatDate(new Date()) },
                        { field: 'License', condition: 'in', value: licenseTemp }
                    ]
                }) as any;
                const tempQuiz: QuizScore[] = await RRServer.getData({
                    db, collection: RRServer.tables.QUIZ, query: [
                        { field: 'DateStamp', condition: '==', value: formatDate(new Date()) },
                        { field: 'License', condition: 'in', value: licenseTemp }
                    ]
                }) as any;

                driverPerformances.push(...tempPerformance);
                quizPerformances.push(...tempQuiz);
            }

            const tempAttemptsData: TempAttemptsData[] = value.licenses.map((license, index) => {
                return {
                    license,
                    name: value.names[index],
                    attempts: driverPerformances.filter((val) => val.License === license),
                    quiz: quizPerformances.filter((val) => val.License === license)
                };
            });

            for (let i = 0; i < tempAttemptsData.length; i++) {
                let totalScore: number = 0;
                let attemptedLevels: string[] = [];
                let attemptedQuiz: string[] = [];
    
                tempAttemptsData[i].attempts.forEach((att) => {
                    if (!attemptedLevels.includes(att.Level)) {
                        attemptedLevels.push(att.Level);
                        totalScore += (att.Score * 0.2); 
                    }
                });
                if (special) {
                    const rulesScore = tempAttemptsData[i].quiz.filter((val) => val.Category !== 'HazChem');
                    if (rulesScore.length > 0) {
                        totalScore += (rulesScore[0].Score * 2);
                    }
                }
                else {
                    tempAttemptsData[i].quiz.forEach((att) => {
                        if (!attemptedQuiz.includes(att.Category)) {
                            attemptedQuiz.push(att.Category);
                            totalScore += (att.Score);
                        }
                    });
                }

                topFive.push({
                    no: (i + 1),
                    name: tempAttemptsData[i].name,
                    score: totalScore
                });
            }

            let totalScore = 0;
            let numAttempts = topFive.length;
            topFive.forEach((tf) => {
                totalScore += tf.score;
            });
            averageScore = (totalScore / numAttempts);

            topFive.sort((a, b) => b.score - a.score);
            topFive = topFive.filter((val, index) => (index < 5));

            return {
                location: value.location,
                numDrivers,
                averageScore,
                topFive
            }
        });

        const summary = await Promise.all(summaryPromise);

        this.setState({ summary });
    }

    render() {
        const { summary } = this.state;
        // console.log(summary,"summary");
        function GetFormattedDate() {
            var todayTime = new Date();
            var month = todayTime.getMonth() + 1;
            var monthFormat = month.toString.length === 1 ? `0${month}` : month
            var day = todayTime.getDate();
            var dayFormat = day < 10 ? `0${day}` : day
            var year = todayTime.getFullYear();
            return monthFormat + "/" + dayFormat + "/" + year;
        }
        const ifSummary =
            summary.filter((loc: any) => {
                return loc.location === this.props.userLocations[0]
            })

        return (
            <>
                <TopBar title="Dashboard" logout={this.props.logout} />

                <div className='welcomeCard'>
                    <div className="info">
                        {/* <h1>{`Hello, ${this.state?.currentLoggedInSchool?.drivingSchoolName}!`}</h1> */}
                        <p>Welcome User</p>
                    </div>
                    <div className="image">
                        {/* <img src="https://download.logo.wine/logo/Indian_Oil_Corporation/Indian_Oil_Corporation-Logo.wine.png" alt="WelcomeCard Image" /> */}
                    </div>
                </div>

                <div className='contentContainer dash'>
                    <div className="dashTitle">
                        <span className="title">Simulator Based Training Module</span>
                        <span className="subTitle">Performance Analysis Portal</span>
                    </div>
                    <span>Today's Update -{GetFormattedDate()}</span>




                    {summary.filter((loc: any) => {
                        return loc.location === this.props.userLocations[0]
                    }).map((thisLocation: SummaryData) => {
                        return <>

                            <div className="updateCards">
                                <div className="updateCard location">
                                    <div className="text">
                                        <span className="title">Location</span>
                                        <span className="data">{thisLocation.location}</span>
                                    </div>

                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-map" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.502.502 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103zM10 1.91l-4-.8v12.98l4 .8V1.91zm1 12.98 4-.8V1.11l-4 .8v12.98zm-6-.8V1.11l-4 .8v12.98l4-.8z" />
                                        </svg>
                                    </div>
                                </div>

                                <div className="updateCard trainedDriversCount">
                                    <div className="text">
                                        <span className="title">No. Of Drivers Trained</span>
                                        <span className="data">{thisLocation.numDrivers}</span>
                                    </div>
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-person-check" viewBox="0 0 16 16">
                                            <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                                            <path fillRule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                        </svg>
                                    </div>
                                </div>

                                <div className="updateCard averageScore">
                                    <div className="text">
                                        <span className="title">Average Score</span>
                                        <span className="data">{Math.round(thisLocation.averageScore)}</span>
                                    </div>
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-percent" viewBox="0 0 16 16">
                                            <path d="M13.442 2.558a.625.625 0 0 1 0 .884l-10 10a.625.625 0 1 1-.884-.884l10-10a.625.625 0 0 1 .884 0zM4.5 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm7 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="dashBot">
                                <TopFive topFive={thisLocation.topFive} />
                            </div>
                        </>

                    })}
                    {
                        !ifSummary &&
                        <>
                            <Skeleton active={false} loading={true}>
                            </Skeleton>
                            <h2 style={{ width: "100%" }}>Live training status coming soon. Stay Tuned</h2>
                            <Skeleton active={false} loading={true}>
                            </Skeleton>
                        </>
                    }
                    <Footer />
                </div>
            </>
        );
    }
}

export default Dashboard;

export interface TempLicenseData {
    location: string,
    names: string[],
    licenses: string[]
}

export interface TempAttemptsData {
    license: string,
    name: string,
    attempts: DriverAttempts[],
    quiz: QuizScore[]
}