import { Component } from 'react';
import '../Sidebar/Sidebar.css'
import minLogo from '../../assets/SMILES.png'
import maxLogo from '../../assets/RRlogo.png';

import { ReactComponent as Dashboard_B } from '../../assets/bnw/Black/dashboard_B.svg';
import { ReactComponent as Dashboard } from '../../assets/bnw/White/dashboard.svg';
import { ReactComponent as Scores_B } from '../../assets/bnw/Black/score_B.svg';
import { ReactComponent as Scores } from '../../assets/bnw/White/Score.svg';
import { ReactComponent as Stats_B } from '../../assets/bnw/Black/StudentStat_B.svg';
import { ReactComponent as Stats } from '../../assets/bnw/White/StudentStat.svg';
import { ReactComponent as LiveClasses_B } from '../../assets/bnw/Black/Classes_B.svg';
import { ReactComponent as LiveClasses } from '../../assets/bnw/White/Classes.svg';
import {ReactComponent as DrivingSchool_B} from '../../assets/bnw/Black/drivingClasses_B.svg'
import {ReactComponent as DrivingSchool} from '../../assets/bnw/White/drivingClasses.svg'


// import { ReactComponent as DashboardIcon } from '../../assets/svgs/Dashboard.svg'
// import { ReactComponent as DashboardIconW } from '../../assets/svgs/Dashboard_W.svg'
// import { ReactComponent as DrivingSchoolIcon } from '../../assets/svgs/DrivingSchool.svg'
// import { ReactComponent as DrivingSchoolIconW } from '../../assets/svgs/DrivingSchool_W.svg'
import { Link } from 'react-router-dom';

const superAdminRoutes = [
    {
        path: '/dashboard',
        label: 'Dashboard',
        icon: <Dashboard style={{ width: "20", height: "20" }} />,
        activeIcon: <Dashboard_B style={{ width: "20", height: "20", color: "black" }} />
    },
    {
        path: '/cumulativeReport',
        label: 'Cumulative Report',
        icon: <Scores style={{ width: "20", height: "20" }} />,
        activeIcon: <Scores_B style={{ width: "20", height: "20", color: "black" }} />
    },
    {
        path: '/overallScores',
        label: 'Overall Scores',
        icon: <Stats style={{ width: "20", height: "20" }} />,
        activeIcon: <Stats_B style={{ width: "20", height: "20", color: "black" }} />
    },
    {
        path: '/completed',
        label: 'Completed',
        icon: <LiveClasses style={{ width: "20", height: "20" }} />,
        activeIcon: <LiveClasses_B style={{ width: "20", height: "20", color: "black" }} />
    },
    // {
    //     path: '/completed',
    //     label: 'Completed',
    //     icon: <LiveClasses style={{ width: "20", height: "20" }} />,
    //     activeIcon: <LiveClasses_B style={{ width: "20", height: "20", color: "black" }} />
    // },
    // {
    //     path: '/addUser',
    //     label: 'Add User',
    //     icon: <DrivingSchool style={{width:"20",height:"20"}}/>,
    //     activeIcon: <DrivingSchool_B style={{width:"20",height:"20"}}/>
    // }
]

interface SidebarState {
    currentPath: any
}

class SideBar extends Component<{}, SidebarState> {

    state: SidebarState = {
        currentPath: null
    }

    handleClick = (e: any, path: any) => {
        // if (!e.target.classList.contains('active')) {
        //     return e.target.classList.add('active')
        // }
        this.setState({ currentPath: path })
        const menusEl = document.querySelectorAll('.side-bar ul li')
        Array.from(menusEl).map(menu => {
            if (menu.classList.contains('active')) {
                menu.classList.remove('active')

            }
        })

        if (e.target.tagName === 'LI') {
            e.target.classList.add('active')

        } else if (e.target.tagName === 'SPAN') {
            e.target.parentElement.parentElement.classList.add('active')
        } else if (e.target.tagName === 'path') {
            e.target.parentElement.parentElement.parentElement.parentElement.classList.add('active')
        }
    }

    render() {
        const currentUrl = this.state.currentPath ? this.state.currentPath : window.location.href

        return (
            <>
                <aside className="side-bar-wrap">
                    <>
                        <nav className="side-bar">
                            <div className="logo-area" style={{ left: "-10px", padding: "30px", margin: "5px", borderRadius: "10px" }}>
                                <img className="min" src={minLogo} alt="min-logo" style={{ width: "52px", height: "51px" }} />
                                <img className="max" src={maxLogo} alt="max-logo" />
                            </div>
                            <ul>
                                {/* {isSuperAdmin && */}
                                {superAdminRoutes.map((route) => {
                                    // console.log("Dddddd")
                                    return <li className={`${currentUrl.endsWith(route.path) ? 'active' : ''}`} onClick={(e: any) => this.handleClick(e, route.path)} key={'sidemenu' + route.label} >
                                        <b></b>
                                        <b></b>
                                        <Link to={route.path} onClick={(e: any) => this.handleClick(e, route.path)}>
                                            {currentUrl.endsWith(route.path) ?
                                                // <p>{currentUrl}JJ</p> : <p>{currentUrl}II</p>
                                                <span className="activeIcon">
                                                    {route.activeIcon}
                                                    {/* {currentUrl.endsWith(route.path) ? route.activeIcon : route.icon} */}
                                                </span>
                                                :
                                                <span className="icon">
                                                    {route.icon}
                                                    {/* {currentUrl.endsWith(route.path) ? route.activeIcon : route.icon} */}
                                                </span>
                                            }

                                            <span className="title" onClick={(e: any) => this.handleClick(e, route.path)} >{route.label}</span>
                                        </Link>
                                    </li>
                                })
                                }
                            </ul>
                        </nav>
                    </>
                </aside>
            </>
        );
    }
}
export default SideBar;