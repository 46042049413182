import { Component } from 'react';
import { RRServer } from '../../utils/lib';
import TopBar from '../TopBar'
import { db } from '../../utils/Firebase';
import moment from 'moment';
import { collection, deleteDoc, doc, getDoc, getDocs, query, where } from 'firebase/firestore';

interface DrivingSchoolInterface {
  pricePerCode: string | number | readonly string[] | undefined;
  sosNumber: string | number | readonly string[] | undefined;
  password: string | number | readonly string[] | undefined;
  code: string;
  id: string;
  drivingSchoolName: string;
  currentCount: string;
}

interface UserStateProps {
  schools: DrivingSchoolInterface[];
  selectedSchool: DrivingSchoolInterface | null;
  userCode: string;
  filterSchoolId: string;
  isToggle: boolean;
  isSearch: boolean;
  schoolsObj: any;
  currentLoggedInSchool: any;
  userType:any;
}

class Profile extends Component<{ logout: () => void, userEmail: string }, UserStateProps> {
  unregisterAuthObserver: any;

  constructor(props: any) {
    super(props);
    this.state = {
      schools: [],
      selectedSchool: null,
      userCode: "",
      filterSchoolId: "",
      isToggle: true,
      isSearch: false,
      schoolsObj: null,
      currentLoggedInSchool: null,
      userType:null
    };
  }
  async componentDidMount() {

    const userDetails: any = await RRServer.getData({ 
        db, collection: RRServer.tables.ATTEMPTS, query: [
            { field: 'users', condition: '==', value:"WHhF6GIKuOSmW6W2VqIQ81NrmLA3" }
            // { field: 'users', condition: '==', value:"0DNHkfgTmfeADiynGXs4VtLE5ws1" }

            
        ]
    }) as any;
    // this.setState({ scoresDetails: [...quizAttempts, ...driverAttempts] });

    // this.getDriversForLocation(locations, '', '');
    // this.setState({ locations, selectedLocation: (locations.length > 0 && locations[0] || ''), data });
  }

  // deleteData = async () => {
  //   const idsToDelete = await getDocs(query(collection(db, 'driver_id'), where('Location', '==', 'Vaels International School')));
  //   const licenses = idsToDelete.docs.map((d) => d.data().License);
  //   idsToDelete.forEach((id) => {
  //     deleteDoc(doc(db, 'driver_id', id.id));
  //   });
  //   licenses.forEach(async (license) => {
  //     const dataToDelete = await getDocs(query(collection(db, 'driver_data'), where('License', '==', license)));
  //     const emerToDelete = await getDocs(query(collection(db, 'driver_emergency'), where('License', '==', license)));
  //     const resultsToDelete = await getDocs(query(collection(db, 'driver_results'), where('License', '==', license)));
  //     const attemptsToDelete = await getDocs(query(collection(db, 'driver_attempts'), where('License', '==', license)));
  //     const quizToDelete = await getDocs(query(collection(db, 'quiz_score'), where('License', '==', license)));
  //     // console.log(
  //     //   dataToDelete.docs.map(d => d.data()),
  //     //   emerToDelete.docs.map(d => d.data()),
  //     //   resultsToDelete.docs.map(d => d.data()),
  //     //   attemptsToDelete.docs.map(d => d.data()),
  //     //   quizToDelete.docs.map(d => d.data())
  //     // );
  //     dataToDelete.forEach((dtd) => deleteDoc(doc(db, 'driver_data', dtd.id)));
  //     emerToDelete.forEach((etd) => deleteDoc(doc(db, 'driver_emergency', etd.id)));
  //     resultsToDelete.forEach((rtd) => deleteDoc(doc(db, 'driver_results', rtd.id)));
  //     attemptsToDelete.forEach((att) => deleteDoc(doc(db, 'driver_attempts', att.id)));
  //     quizToDelete.forEach((qtd) => deleteDoc(doc(db, 'quiz_score', qtd.id)));
  //   });
  // }

  render() {
    const { schools, filterSchoolId } = this.state;
    // let { url } = this.props.match;

    let dataSource = schools.map((school) => ({ key: school.id, ...school }));

    if (filterSchoolId) {
      dataSource = dataSource.filter((d) => d.id === filterSchoolId);
    }

    const user = JSON.parse(localStorage.getItem('user') || '{}');

    return (
      <>
 
        <TopBar title="Profile" logout={this.props.logout} />

        <div className='contentContainer'>
          <div className='contentTop'>
            <h5>{this.state.isToggle ? 'Profile' : 'Add Driving School'}</h5>
          </div>

          <div className="profileContent">
            <div className="sectionLeft">
              <div className="cardProfile">
                <div className="profilePic">
                  {!this.state?.currentLoggedInSchool?.drivingSchoolUnionImg ?
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARMAAAC3CAMAAAAGjUrGAAAANlBMVEX///+/v7+7u7vt7e3z8/P5+fnAwMD8/PzDw8PGxsbLy8vOzs7g4OD39/fr6+vV1dXb29vZ2dkWhsU1AAAFY0lEQVR4nO2c24KrIAxFj3hFEPX/f/YU6sy0FUh0FGxnr+dWYJuEEMB//wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACwpm3b2jFYKktpHOVQt7l7l4x2KI2eRtXLrmmKH8QrRdF0vRonbcqqzt3r86i0ksV9vHzc7xupZl1+nDTV3GwTwyNO109myD2Qw6jVr/R4VKZTuvqEWKOPEeRbl6LXb24vlTzISJ50kfqNrWU8XpFFFlXlHts+zDmCLLKoN3Sh9qjYGlbl3WZofbIiTpUp9yi3MJwQW32iFCb3SNlMSRRxqvTvEVaqLpkkxXs4UHvWBBwUpStzj5nANIklsaqMV87hTp+AQ+jcIw9iGrr353DVWJs8kjyrcsVYW2YUxIlyvVib1UgWVdSlYm2VLZI8IS4Ua1OsblgIeZVYm2sG9nGNWFt3uXV44gqxNvd8syZrXlsPpZ5zK+Aleaxt68roWcmm2LiLlQ7RJVKlvZnFNPZds3VHLweimc/bDLpZRWn0qGR3XbPwIxqly0N1aasfJd5KikduPVf6iFp2baZRvoWDsBCi/50sgx67D9HigZsse1WpJvl5etzZtxJqdYayYTrUDhMZP9VCFrqtigz9ZwtyQ2zbdc9bNEzFpvXyRepBZ7PFearcnU3EBudp/4aV3JjZmqjcXU0Hd+lj/kJ4vcNN2/6O5xTsKDvm7mdKBKtQW7E8R1xxiSx29KrnaNIzmu7tZQBjJlVcRhch5Ow6tXERLxibP5SZiGJ8NLdhSnoCKYRQj6fYWr1hXcLIZSkzWQdqk+bwXgQxriohFX/PjZSkjj7qe5dkMFpr81XwPfYA/Va+z5kMpevUok/JNGA6l43u8jZ3rzGq+bpqtFTwhozbfkuKUdowcu+UnO7D5C1j6RQllsJKZxbPZaZbeHGq5CssuPG/+K/oK/IFf0NWliIv3Enis0gndKY9c2f59fqNiJEtiqQ0CT+ka0ONCKc0PYefgEu5vLvTQtZMUQQhSRt8hnshgcPQwiY+OZYELhaUgT43AxELvh5CaDKENHGtB1V3lhLq24nYZsMJlbXsln4IpUmwARkftDv1kn6hVMcHbc2XXuTv1cT6bdQ5rGvVVOtHMxPO4YxbUk/Zq4k1kzmquMxgKDXlsKJmGMpOTYQhrcD+hLekPgxFW4GdkanHUJoEUnv7N+oCTs+x0yOx/ky+hVuYJU9NEZoE8hM7Xip9t3Y6Uc0fCmO81nqp+ZDM2bw5uo1VwVn68UdJp2POi7LOQ03HdFXJt3CyVkrPaSO1qj6YmdNeQwrHKVN7Bm9rUbRb9GlzWZ5dipbIZXmHUDwPrjnzbMex5eOwoYKRkA3xrrPKsb7JgxTb0Xn/expWE3qJZ3PJiIlzqrGWtax8O0msCcNOopqwD+Kvxbdq0mej5SV9J/o6G6YknjzIik2bqYqVGk5AcxJnm20G3V6wr6yv53NeiJ8SJ/c2tSfbs8YbnAzJbO2HdcWMkfm4JCbpPabO29UXpshCbcvRLbP+FFjMAhesb6rVP89koF+CHfZ6OAubjoJWr7iqJ9G8LWaUSanpAGa9oyrLr3EM9fJVvNuQDvmqTnyeFXm+xhKfDvlBdCdRQ8l15S4e5jYE0Z3EIgp7pj+aWETZeP51D204I0vQeojwZluSm8V1aKY/3W9jhLZlBf9Q428IXJHlHYA6Db+lJIt
                  wreeliC73x+J8peKUpqtfj2slMtEoq+8lpv5Q26MqwnNEKAvm4eaVWI5aJKWcentNspHjhb
                  6FNty/0dtI9c6fwwQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADgT/Afu0hPLsB+Gd8AAAAASUVORK5CYII=" alt="" /> : <img src={this.state?.currentLoggedInSchool?.drivingSchoolUnionImg} alt="Profile Picture" width={100} />}
                </div>
                <div className="profileDetails">
                  <div className="detailItem">
                    <h4 className="title">My Profile</h4>
                  </div>

                  {/* <div className="detailItem">
                    <span>Last Login on  {user.user.lastLoginAt}</span>
                  </div> */}

                  <div className="detailItem">
                    {/* new Date(user.user.lastLoginAt).toLocaleDateString("en-US") */}

                    <span>{moment.unix(user.user.lastLoginAt).format("MM/DD/YYYY")}</span>
                  </div>

                  <div className="detailItem">
                    <span className="mail"> {user.user.email}</span>
                    <span className="smsalert"> Email : {user.user.emailVerified ? <span className='green'>Verified</span> : <span className='red'>Not Verified</span>}</span>
                  </div>

                  {/* <button onClick={this.deleteData}>Delete data</button> */}
                  {/* <div className="detailItem">
                    <span className="smsalert"> Email : {user.user.emailVerified? "VERIFIED" : "NOT VERIFIED"}</span>
                  </div> */}

                </div>

              </div>
            </div>

          </div>
        </div>

      </>
    );
  }
}

// export default withRouter(UserAdd);
export default Profile;


