import { addDoc, collection, deleteDoc, doc, DocumentData, endAt, Firestore, getDoc, getDocs, orderBy, OrderByDirection, query, QueryConstraint, startAt, updateDoc, where, WhereFilterOp } from 'firebase/firestore';

export const formatTime = (dateStr: string) => {
    const dt = new Date(dateStr)
    const h = dt.getHours();
    const m = dt.getMinutes();
    return (h < 10 ? ('0' + h) : h) + ':' + (m < 10 ? ('0' + m) : m);
}

export const formatDate = (date: Date): string => {
    const dayNum = date.getDate();
    const monthNum = date.getMonth() + 1;
    const yearNum = date.getFullYear();

    let result: string = (dayNum < 10 ? ('0' + dayNum.toString()) : dayNum.toString());
    result += '-' + (monthNum < 10 ? ('0' + monthNum.toString()) : monthNum.toString());
    result += '-' + yearNum;

    return result;
}

export const getLoggedInUser = () => {
    const userStr = localStorage.getItem('user') || '{}';
    const user = JSON.parse(userStr);
    return user.user;
}
export  const getLoggedInUserName=()=>{
    const user=getLoggedInUser();
    return user.displayName;
}

export interface WhereQuery {
    field: string,
    condition: WhereFilterOp,
    value: string | string[] | number | number[]
}

export interface ListParamsInterface {
    db: Firestore,
    collection: string,
    query: WhereQuery[],
    orderBy?: string,
    direction?: OrderByDirection,
    startAt?: number,
    endAt?: number
}

export interface AddParamsInterface {
    db: Firestore,
    collection: string,
    data: any
}

export interface UpdateParamsInterface extends AddParamsInterface {
    doc: string
}

export interface DeleteParamsInterface {
    db: Firestore,
    collection: string,
    doc: string
}

export class RRServer {
    static async insertData(data: AddParamsInterface): Promise<boolean> {
        try {
            const docRef = await addDoc(collection(data.db, data.collection), data.data);
            return true;
        }
        catch {
            return false;
        }
    }

    static async updateData(data: UpdateParamsInterface): Promise<boolean> {
        try {
            const docRef = doc(data.db, data.collection, data.doc);
            await updateDoc(docRef, data.data);
            return true;
        }
        catch(err) {
            console.error(err);
            return false;
        }
    }

    static async deleteData(data: DeleteParamsInterface): Promise<boolean> {
        try {
            const docRef = doc(data.db, data.collection, data.doc);
            await deleteDoc(docRef);
            return true;
        }
        catch {
            return false;
        }
    }

    static async getDataById(db: Firestore, collectionId: string, id: string): Promise<DocumentData | undefined> {
        const docRef = doc(db, collectionId, id);
        const docData = await getDoc(docRef);
        return docData.data();
    }

    static async getData(data: ListParamsInterface): Promise<DocumentData[]> {
        const collectionRef = collection(data.db, data.collection);
        const queryConstraints: QueryConstraint[] = [];

        for (const queryData of data.query) {
            queryConstraints.push(where(queryData.field, queryData.condition, queryData.value));
        }

        if (data.orderBy && data.direction) {
            queryConstraints.push(orderBy(data.orderBy, data.direction));
        }
        if (data.startAt) {
            queryConstraints.push(startAt(data.startAt));
        }
        if (data.endAt) {
            queryConstraints.push(endAt(data.endAt));
        }

        const q = query(collectionRef, ...queryConstraints);
        const querySnapshot = await getDocs(q);

        const result: DocumentData[] = [];
        querySnapshot.forEach((doc) => result.push(doc.data()));

        return result;
    }

    static tables = {
        DRIVERID: 'driver_id', DRIVERDATA: 'driver_data', DRIVEREMER: 'driver_emergency', RESULTS: 'driver_results',
        ATTEMPTS: 'driver_attempts', QUIZ: 'quiz_score'
    }
}