import { FC, FormEvent, useEffect, useState } from 'react';
import { DriverID, UserType } from '../../utils/types';
import { useNavigate } from 'react-router-dom';
import TopBar from '../TopBar';
import { RRServer } from '../../utils/lib';
import { db } from '../../utils/Firebase';
import { collection, deleteDoc, doc, getDocs, query, where } from 'firebase/firestore';

type DriverProps = {
    userType: UserType,
    userEmail: string,
    logout: () => void
}

type TableData = {
    headers: string[],
    data: any[][],
    id: string[]
}

export const EditDrivers: FC<DriverProps> = ({ userEmail, logout, userType }) => {
    const navigate = useNavigate();

    const [locations, setLocations] = useState<string[]>([]);
    const [currentLocation, setCurrentLocation] = useState<string>('');
    const [currentTable, setCurrentTable] = useState<string>('driver_id');
    const [dataToEdit, setDataToEdit] = useState<TableData | undefined>(undefined);

    const tables: { key: string, value: string }[] = [
        { key: 'Driver Identification Data', value: 'driver_id' },
        { key: 'Driver Personal Data', value: 'driver_data' },
        { key: 'Driver Emergency Data', value: 'driver_emergency' },
        { key: 'Driver Test Result Data', value: 'driver_results' },
        { key: 'Driver Simulator Score Data', value: 'driver_attempts' },
        { key: 'Driver Quiz Score Data', value: 'quiz_score' }
    ];

    useEffect(() => {
        if ((userType !== UserType.SUPER_ADMIN) || ((userEmail !== 'test@redchariots.com') && (userEmail !== 'test_iit@redchariots.com'))) {
            navigate('/dashboard');
            return;
        }

        getLocations();
    }, []);

    const getLocations = async () => setLocations((await RRServer.getDataById(db, 'locations', 'locations') as any)['locations'] as string[]);

    const onSearchSubmit = async (ev: FormEvent) => {
        ev.preventDefault();

        if (currentLocation === '' || currentTable === '') {
            return alert('Please fill all the fields!');
        }

        const id_query = query(collection(db, 'driver_id'), where('Location', '==', currentLocation));
        const id_snap = await getDocs(id_query);
        if (currentTable === 'driver_id') {
            if (id_snap.docs.length > 0) {
                const headers = ['License', ...Object.keys(id_snap.docs[0].data()).filter((h) => h !== 'License')];
                setDataToEdit({
                    headers,
                    data: id_snap.docs.map((ltg) => {
                        return headers.map((val) => (ltg.data() as any)[val]);
                    }),
                    id: id_snap.docs.map((ltg) => ltg.id)
                });
            }
        }
        else {
            if (id_snap.docs.length > 0) {
                const licenses = id_snap.docs.map((ltg) => ltg.data().License) as string[];
                const finalData: any[][] = [];
                const finalIds: string[] = [];

                const first_data_query = query(collection(db, currentTable), where('License', '==', licenses[0]));
                const first_data_snap = await getDocs(first_data_query);
                if (first_data_snap.docs.length > 0) {
                    const headers = ['License', ...Object.keys(first_data_snap.docs[0].data()).filter((h) => h !== 'License')];
                    finalData.push(headers.map((h) => (first_data_snap.docs[0].data() as any)[h]));
                    finalIds.push(first_data_snap.docs[0].id);
                    for (let i = 1; i < licenses.length; i++) {
                        const data_query = query(collection(db, currentTable), where('License', '==', licenses[i]));
                        const data_snap = await getDocs(data_query);
                        if (data_snap.docs.length > 0) {
                            finalData.push(...data_snap.docs.map((d) => headers.map((val) => (d.data() as any)[val])));
                            finalIds.push(...data_snap.docs.map((d) => d.id));
                            setDataToEdit({
                                headers,
                                data: finalData,
                                id: finalIds
                            });
                        }
                    }
                }
                else {
                    return alert('Driver data is missing for ' + licenses[0]);
                }
            }
        }
    }

    const goToEditPage = (dt: any[]) => {
        if (currentTable == 'quiz_score') {
            const categoryIndex = dataToEdit?.headers.findIndex((h) => h === 'Category');
            if (categoryIndex !== -1 && categoryIndex) {
                navigate('/edit-drivers/' + currentTable + '/' + dt[0] + '/Category/' + dt[categoryIndex]);
            }
        }
        else if (currentTable === 'driver_attempts') {
            const levelIndex = dataToEdit?.headers.findIndex((h) => h === 'Level');
            if (levelIndex !== -1 && levelIndex) {
                navigate('/edit-drivers/' + currentTable + '/' + dt[0] + '/Level/' + dt[levelIndex]);
            }
        }
        else {
            navigate('/edit-drivers/' + currentTable + '/' + dt[0] + '/rr-empty/rr-empty');
        }
    }

    const deleteDriver = async (id: string) => {
        const answer = window.confirm('This will delete all the data in this table connected to this license. Are you sure you want to do this? Once deleted, it will be lost forver.');
        if (answer) {
            const docRef = doc(db, currentTable, id);
            await deleteDoc(docRef);
            alert('Data has been deleted. It might take some time to reflect in the UI');
        }
    }

    return (
        <div>
            <TopBar logout={logout} title='Edit Drivers' />
            <div style={{ padding: '3%' }}>
                <h4 className='text-center font-weight-bold'>Edit Driver Details</h4>
                <form onSubmit={onSearchSubmit}>
                    <div className='form-group'>
                        <label htmlFor='locationname'>Location name:</label>
                        <select className='form-control' id='locationname' onChange={(ev) => setCurrentLocation(ev.target.value)} value={currentLocation}>
                            {
                                locations.map((loc, locIndex) =>
                                    <option key={'locIndex' + locIndex} value={loc}>{loc}</option>
                                )
                            }
                        </select>
                    </div>
                    <div className='form-group'>
                        <label htmlFor='tablename'>Table name:</label>
                        <select className='form-control' onChange={(ev) => setCurrentTable(ev.target.value)} value={currentTable}>
                            {
                                tables.map((table, locIndex) =>
                                    <option key={'locIndex' + locIndex} value={table.value}>{table.key}</option>
                                )
                            }
                        </select>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <button type='submit' className='btn btn-primary'>Show Data</button>
                    </div>
                </form>

                <h4 style={{ marginLeft: '10px', marginTop: '20px', fontWeight: 'bold' }}>Driver Details:</h4>
                <table className='table table-bordered table-hover' style={{ margin: '10px', width: 'calc(100% - 20px)' }}>
                    <thead>
                        <tr>
                            <th style={{ verticalAlign: 'middle', textAlign: 'center', fontWeight: 'bold' }}>S No.</th>
                            {
                                dataToEdit?.headers.map((headers, hIndex) =>
                                    <th style={{ verticalAlign: 'middle', textAlign: 'center', fontWeight: 'bold' }} key={'dHeader' + hIndex}>{headers}</th>
                                )
                            }
                            <th style={{ verticalAlign: 'middle', textAlign: 'center', fontWeight: 'bold' }}>Edit</th>
                            <th style={{ verticalAlign: 'middle', textAlign: 'center', fontWeight: 'bold' }}>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataToEdit?.data.map((dt, dtIndex) =>
                                <tr key={'data' + dtIndex}>
                                    <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{dtIndex + 1}</td>
                                    {
                                        dt.map((d, dKey) =>
                                            <td key={'dtkey' + dKey} style={{ verticalAlign: 'middle', textAlign: 'center' }}>{d}</td>
                                        )
                                    }
                                    <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                        <button className='btn btn-warning' onClick={()=>goToEditPage(dt)}>Edit</button>
                                    </td>
                                    <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                        <button className='btn btn-danger' onClick={()=>deleteDriver(dataToEdit.id[dtIndex])}>Delete</button>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}