import { Component } from 'react';
import Footer from '../Footer';
import { DatePicker } from "antd";
import TopBar from '../TopBar';
import OverallScoresMini from './MiniTableCards/OverallScoresMini';
import { CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Chart, BarController, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { DriverAttempts, QuizScore, UserType } from '../../utils/types';
import { RRServer } from '../../utils/lib';
import { db } from '../../utils/Firebase';
import { TempAttemptsData } from './Dashboard';
import { DocumentData } from 'firebase/firestore';

Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(BarController);
Chart.register(BarElement);
Chart.register(Title);
Chart.register(Tooltip);
Chart.register(Legend);
Chart.register(...registerables);

interface CRState {
    data: TerminalSummary,
    locations: string[],
    selectedLocation: string
}
interface CRProps {
    logout: () => void,
    type: UserType,
    userLocations: string[],
    userEmail: string
}

class CumulativeReport extends Component<CRProps, CRState> {
    private options: { responsive: boolean, plugins: { legend: { position: 'top' }, title: { display: boolean, text: string } } } = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Chart.js Bar Chart',
            },
        },
    }

    constructor(props: any) {
        super(props);

        this.state = { data: { avgScore: 0, data: { labels: [], datasets: [] }, name: '', numDays: 0, numDrivers: 0, topFive: [] }, locations: [], selectedLocation: '' };
    }

    async componentDidMount() {
        const { userLocations, userEmail } = this.props;

        let special = false;
        let ap = false;
        const userData = await RRServer.getData({
            collection: 'users', db, query: [
                { field: 'email', condition: '==', value: userEmail },
            ]
        });
        let specialLocs = await RRServer.getDataById(db, 'locations', 'special-locations') as DocumentData;
        if (userData.length > 0) {
            special = userData[0].special as boolean;
            ap = userData[0].ap as boolean;
        }

        const locationData = await RRServer.getData({
            collection: 'locations', db, query: []
        });
        let locations: string[] = [];

        if (special && userLocations[0] === 'all') {
            locations = specialLocs['bus-locations'] as string[];
        }
        else if (special) {
            locations = userLocations.filter((val) => (specialLocs['bus-locations'] as string[]).includes(val));
        }
        else if (ap && userLocations[0] === 'all') {
            locations = specialLocs['ap-locations'] as string[];
        }
        else if (ap) {
            locations = userLocations.filter((val) => (specialLocs['ap-locations'] as string[]).includes(val));
        }
        else if (locationData.length > 0) {
            if (userLocations[0] === 'all') {
                locations = (locationData[0].locations as string[]).filter((val) => !specialLocs['bus-locations'].includes(val));
            }
            else {
                locations = (locationData[0].locations as string[]).filter((val) => (userLocations.includes(val) && !specialLocs['bus-locations'].includes(val)));
            }
        }

        if (locations.length > 0) {
            this.getDataForLocation(locations[0]);
        }

        this.setState({ locations, selectedLocation: (locations.length > 0 && locations[0] || '') });
    }

    getDataForLocation = async (location: string) => {
        const driverIdData = await RRServer.getData({
            db, collection: RRServer.tables.DRIVERID, query: [
                { field: 'Location', condition: '==', value: location }
            ]
        });

        const specialLocsDoc = await RRServer.getDataById(db, 'locations', 'special-locations');
        let specialLocs: string[] = [];
        let apLocs: string[] = [];
        if (specialLocsDoc) {
            specialLocs = specialLocsDoc['bus-locations'];
            apLocs = specialLocsDoc['ap-locations'];
        }
        const special = specialLocs.includes(location);
        const ap = apLocs.includes(location);

        const tempAttemptsDataPromises: Promise<TempDateAttempts>[] = driverIdData.map(async (idData) => {
            const license = idData.License as string;
            const name = idData.Name as string;
            const date = idData.Date_Registered as string;

            const attemptsData: DriverAttempts[] = await RRServer.getData({
                db, collection: RRServer.tables.ATTEMPTS, query: [
                    { field: 'License', condition: '==', value: license }
                ]
            }) as any;

            const quizData: QuizScore[] = await RRServer.getData({
                db, collection: RRServer.tables.QUIZ, query: [
                    { field: 'License', condition: '==', value: license }
                ]
            }) as any;

            return {
                license, name, attempts: attemptsData, quiz: quizData, date
            }
        });

        const tempAttemptsData = await Promise.all(tempAttemptsDataPromises);

        const attemptsByDate: AttemptsByDate[] = [];
        let topFive: { no: number, name: string, score: number }[] = [];
        let counter = 1;
        for (const tad of tempAttemptsData) {
            const date = tad.date;
            let score = 0;
            let attemptedLevels: string[] = [];
            let attemptedQuiz: string[] = [];

            tad.attempts.forEach((att) => {
                if (!attemptedLevels.includes(att.Level)) {
                    if (ap) {
                        attemptedLevels.push(att.Level);
                        score += (0.4 * att.Score);
                    }
                    else {
                        attemptedLevels.push(att.Level);
                        score += (0.2 * att.Score)
                    }
                }
            });
            if (special) {
                const rulesScore = tad.quiz.filter((val) => val.Category !== 'HazChem');
                if (rulesScore.length > 0) {
                    score += (rulesScore[0].Score * 2);
                }
            }
            else {
                tad.quiz.forEach((att) => {
                    if (!attemptedQuiz.includes(att.Category)) {
                        attemptedQuiz.push(att.Category);
                        score += (att.Score);
                    }
                });
            }

            topFive.push({
                no: counter,
                name: tad.name,
                score: score
            });
            counter += 1;

            const index = attemptsByDate.findIndex((val) => val.date === date);

            if (index === -1) {
                attemptsByDate.push({
                    date, numAttempts: 1, score
                });
            }
            else {
                const currAttempts = attemptsByDate[index].numAttempts + 1;
                const totalScore = (attemptsByDate[index].score * attemptsByDate[index].numAttempts) + score;
                const avgScore = (totalScore / currAttempts);
                attemptsByDate[index].numAttempts = currAttempts;
                attemptsByDate[index].score = avgScore;
            }
        }

        topFive.sort((a, b) => { return b.score - a.score });
        topFive = topFive.filter((val, index) => (index < 5));

        let totalScore = 0;
        let totalAttempts = 0;
        attemptsByDate.forEach((val) => {
            totalScore += (val.score * val.numAttempts);
            totalAttempts += val.numAttempts;
        });

        const avgScore = (totalScore / totalAttempts);

        const data: TerminalSummary = {
            avgScore, name: '', numDays: attemptsByDate.length, numDrivers: totalAttempts, data: {
                labels: attemptsByDate.map((val) => { return val.date }),
                datasets: [
                    {
                        label: 'Average Scores per day',
                        backgroundColor: 'rgb(37,115,239,1)',
                        data: attemptsByDate.map((val) => { return val.score })
                    }
                ]
            },
            topFive
        };

        this.setState({ data });
    }

    handleOnchange = (e: any) => {
        this.setState({ selectedLocation: e.target.value }, () => this.getDataForLocation(this.state.selectedLocation));
    }

    render() {
        const { selectedLocation, data, locations } = this.state;

        return (
            <>
                <TopBar title="Dashboard" logout={this.props.logout} />

                <div className='welcomeCard'>
                    <div className="info">
                        <p>Welcome User</p>
                    </div>
                    <div className="image">
                        {/* <img src="https://download.logo.wine/logo/Indian_Oil_Corporation/Indian_Oil_Corporation-Logo.wine.png" alt="WelcomeCard Image" /> */}
                    </div>
                </div>

                <div className='contentContainer dash'>

                    <div className="filter">
                        <div className="locationFilter">
                            {/* <input type="text" placeholder="Search Location" list="data" className='form-control' onChangeCapture={(e) => this.handleOnchange(e)} />
                            <datalist id="data">
                                {locations.map((item, key) =>
                                    <option key={key} value={item} />
                                )}

                            </datalist> */}
                            <select name="loc" className='form-control' onChangeCapture={(e) => this.handleOnchange(e)} >
                                <option>--Select Location--</option>
                                {locations.map((item, key) =>
                                    <option value={item} key={'lockey_' + key}>{item}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="dashTitle">
                        <span className="title">{selectedLocation + ' Summary'}</span>
                    </div>

                    <div className="sectionOne mt-2">
                        <div className="section">
                            <div className="dataCircle red">
                                <span>{data.numDays}</span>
                            </div>
                            <span>No Of Days</span>
                        </div>

                        <div className="section">
                            <div className="dataCircle orange">
                                <span>{data.numDrivers}</span>
                            </div>
                            <span>No Of Drivers</span>
                        </div>

                        <div className="section">
                            <div className="dataCircle green">
                                <span>{Math.round(data.avgScore) + '%'}</span>
                            </div>
                            <span>Avg Score</span>
                        </div>
                    </div>

                    <div className="terminalSummary">
                        <div className="chart">
                            {data && <>
                                <Bar options={this.options} data={data.data} />
                            </>
                            }
                        </div>
                        <div className="overallBestPerformance">
                            <OverallScoresMini scores={data.topFive} />
                        </div>
                    </div>
                    <Footer />
                </div>
            </>
        );
    }
}

export default CumulativeReport;

interface ChartData {
    labels: string[];
    datasets: { label: string, data: number[], backgroundColor: string }[]
}

interface TerminalSummary {
    name: string,
    numDays: number,
    numDrivers: number,
    avgScore: number,
    data: ChartData,
    topFive: { no: number, name: string, score: number }[]
}

interface TempDateAttempts extends TempAttemptsData {
    date: string
}

interface AttemptsByDate {
    date: string,
    score: number,
    numAttempts: number
}