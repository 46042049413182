import { Component, Fragment } from 'react';
import Footer from '../Footer';
import TopBar from '../TopBar';
import OverallScoresMini from './MiniTableCards/OverallScoresMini';
import { auth, db } from '../../utils/Firebase';
// import { UserType } from '../utils/types';
import { DriverAttempts, DriverID, QuizScore, UserType } from '../../utils/types';
import { formatDate, RRServer } from '../../utils/lib';
import { TempAttemptsData, TempLicenseData } from './Dashboard';
import { Skeleton } from 'antd';
import { DocumentData } from 'firebase/firestore';

interface LocationSummary {
    location: string,
    numDrivers: number,
    averageScore: number,
    topFive: { no: number, name: string, score: number }[]
}

class Completed extends Component<{ logout: () => void, userLocations: any, userType: UserType, userEmail: string }, { allLocationsLoaded: boolean, locationSummaries: LocationSummary[], loading: boolean, userTyppe: UserType }> {
    constructor(props: any) {
        super(props);

        this.state = { locationSummaries: [], loading: true, userTyppe: UserType.NONE, allLocationsLoaded: false };
    }


    async componentDidMount() {
        const { userEmail, userLocations, userType } = this.props;

        let special = false;
        let ap = false;
        const userData = await RRServer.getData({
            collection: 'users', db, query: [
                { field: 'email', condition: '==', value: userEmail },
            ]
        });
        if (userData.length > 0) {
            special = userData[0].special as boolean;
            ap = userData[0].ap as boolean;
        }
        let specialLocs = await RRServer.getDataById(db, 'locations', 'special-locations') as DocumentData;
        let allLocs = await RRServer.getDataById(db, 'locations', 'locations') as DocumentData;

        const finalLocations = (userType === UserType.SUPER_ADMIN ? (special ? specialLocs['bus-locations'] : (ap ? specialLocs['ap-locations'] : allLocs['locations'])) : userLocations) as string[];

        const locationSummaries: LocationSummary[] = [];
        let locCount = 0;
        for (const location of finalLocations) {
            const completedDrivers = await RRServer.getData({
                db, collection: RRServer.tables.DRIVERID, query: [
                    { field: 'Location', condition: '==', value: location }
                ]
            }) as DriverID[];

            const value: TempLicenseData = {
                location,
                licenses: completedDrivers.map((v) => v.License),
                names: completedDrivers.map((v) => v.Name)
            };

            let averageScore: number = 0;
            let numDrivers: number = value.licenses.length;
            let topFive: { no: number, name: string, score: number }[] = [];

            const driverPerformances: DriverAttempts[] = [];
            const quizPerformances: QuizScore[] = [];

            for (let i = 0; i < value.licenses.length; i += 10) {
                let licenseTemp: string[] = [];
                for (let j = i; j < Math.min((i + 10), value.licenses.length); j++) {
                    licenseTemp.push(value.licenses[j]);
                }

                const tempPerformance: DriverAttempts[] = await RRServer.getData({
                    db, collection: RRServer.tables.ATTEMPTS, query: [
                        { field: 'License', condition: 'in', value: licenseTemp }
                    ]
                }) as any;
                const tempQuiz: QuizScore[] = await RRServer.getData({
                    db, collection: RRServer.tables.QUIZ, query: [
                        { field: 'License', condition: 'in', value: licenseTemp }
                    ]
                }) as any;

                driverPerformances.push(...tempPerformance);
                quizPerformances.push(...tempQuiz);
            }

            const tempAttemptsData: TempAttemptsData[] = value.licenses.map((license, index) => {
                return {
                    license,
                    name: value.names[index],
                    attempts: driverPerformances.filter((val) => val.License === license),
                    quiz: quizPerformances.filter((val) => val.License === license)
                };
            });

            for (let i = 0; i < tempAttemptsData.length; i++) {
                let totalScore: number = 0;
                let attemptedLevels: string[] = [];
                let attemptedQuiz: string[] = [];

                tempAttemptsData[i].attempts.forEach((att) => {
                    if (!attemptedLevels.includes(att.Level)) {
                        if (ap) {
                            totalScore += (att.Score * 0.4);
                        }
                        else {
                            totalScore += (att.Score * 0.2);
                        }
                        attemptedLevels.push(att.Level);
                    }
                });
                if (special) {
                    const rulesScore = tempAttemptsData[i].quiz.filter((val) => val.Category !== 'HazChem');
                    if (rulesScore.length > 0) {
                        totalScore += (rulesScore[0].Score * 2);
                    }
                }
                else {
                    tempAttemptsData[i].quiz.forEach((att) => {
                        if (!attemptedQuiz.includes(att.Category)) {
                            attemptedQuiz.push(att.Category);
                            totalScore += (att.Score);
                        }
                    });
                }

                topFive.push({
                    no: (i + 1),
                    name: tempAttemptsData[i].name,
                    score: totalScore
                });
            }

            let totalScore = 0;
            let numAttempts = topFive.length;
            topFive.forEach((tf) => {
                totalScore += tf.score;
            });
            averageScore = (totalScore / numAttempts);

            topFive.sort((a, b) => b.score - a.score);
            topFive = topFive.filter((val, index) => (index < 5));

            locationSummaries.push({
                location: value.location,
                numDrivers,
                averageScore,
                topFive
            });
            locCount += 1;

            this.setState({ locationSummaries, loading: false }, () => {
                if (locCount === finalLocations.length) {
                    this.setState({ allLocationsLoaded: true });
                }
            });
        }
    }

    render() {
        const { locationSummaries, loading, allLocationsLoaded } = this.state;

        return (
            <>
                <TopBar title="Dashboard" logout={this.props.logout} />

                <div className='welcomeCard'>
                    <div className="info">
                        <p>Welcome User</p>
                    </div>
                    <div className="image">
                    </div>
                </div>
                <div className='contentContainer dash'>
                    <div className="dashTitle">
                        <span className="title">Simulator Based Training Module</span>
                        <span className="subTitle">Performance Analysis Portal</span>
                    </div>
                    <span>Completed Classes</span>

                    <Skeleton active={true} loading={loading}>
                        {
                            locationSummaries.map((thisLocation: LocationSummary) => {
                                return <Fragment key={'location' + thisLocation.location}>
                                    <div className="updateCards">
                                        <div className="updateCard location">
                                            <div className="text">
                                                <span className="title">Location</span>
                                                <span className="data">{thisLocation.location}</span>
                                            </div>

                                            <div className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-map" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.502.502 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103zM10 1.91l-4-.8v12.98l4 .8V1.91zm1 12.98 4-.8V1.11l-4 .8v12.98zm-6-.8V1.11l-4 .8v12.98l4-.8z" />
                                                </svg>
                                            </div>
                                        </div>

                                        <div className="updateCard trainedDriversCount">
                                            <div className="text">
                                                <span className="title">No. Of Drivers Trained</span>
                                                <span className="data">{thisLocation.numDrivers}</span>
                                            </div>
                                            <div className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-person-check" viewBox="0 0 16 16">
                                                    <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                                                    <path fillRule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                                </svg>
                                            </div>
                                        </div>

                                        <div className="updateCard averageScore">
                                            <div className="text">
                                                <span className="title">Average Score</span>
                                                <span className="data">{Math.round(thisLocation.averageScore)}</span>
                                            </div>
                                            <div className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-percent" viewBox="0 0 16 16">
                                                    <path d="M13.442 2.558a.625.625 0 0 1 0 .884l-10 10a.625.625 0 1 1-.884-.884l10-10a.625.625 0 0 1 .884 0zM4.5 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm7 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dashBot">
                                        <OverallScoresMini scores={thisLocation.topFive} />
                                    </div>
                                </Fragment>
                            })
                        }
                    </Skeleton>
                    {
                        locationSummaries.length === 0
                        &&
                        <>
                            <Skeleton active={false} loading={true} />
                            <h2 style={{ width: "100%" }}>Completed Events coming soon. Stay Tuned</h2>
                            <Skeleton active={false} loading={true} />
                        </>
                    }
                    {
                        !allLocationsLoaded
                        &&
                        locationSummaries.length > 0
                        &&
                        <>
                            <Skeleton active={true} loading={true} />
                            <h3 style={{ width: "100%" }}>Further data is being loaded....</h3>
                        </>
                    }
                    <Footer />
                </div>
            </>
        );
    }
}

export default Completed;