import { Component } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from './components/Pages/Dashboard';
import Sidebar from './components/Sidebar/SideBar'
import RakshakLogin from './components/rakshaklogin/RakshakLogin';
import CumulativeReport from './components/Pages/CumulativeReport';
import PerformanceAnalysis from './components/Pages/PerformanceAnalysis';
import OverallScores from './components/Pages/OverallScores';
import Completed from './components/Pages/Completed';
import AddUser from './components/Pages/AddUser';
import { auth, db } from './utils/Firebase';
import { browserLocalPersistence, setPersistence, signInWithEmailAndPassword, User } from 'firebase/auth';
import { toast } from 'react-toastify';

import './scss/style.css'
import { UserType } from './utils/types';
import { RRServer } from './utils/lib';
import Profile from './components/Pages/Profile';
import { EditLocations } from './components/Pages/EditLocations';
import { EditDrivers } from './components/Pages/EditDrivers';
import { EditSpecificDriver } from './components/Pages/EditSpecificDriver';

class App extends Component<{}, { user: User | null, userType: UserType, userLocations: string[] }> {

  constructor(props: any) {
    super(props);

    this.state = { user: null, userType: UserType.NONE, userLocations: [] };
  }

  componentDidMount() {
    auth.onAuthStateChanged(async (user) => {
      const email = user?.email || '';
      const userData = await RRServer.getData({
        db, collection: 'users', query: [{ field: 'email', condition: '==', value: email }]
      });

      if (userData.length <= 0) {
        alert('Session expired');
        return this.logout();
      }

      const userType = userData[0].type as UserType;
      const userEmail = userData[0].email as any;
      const userLocations = userData[0].locations as string[];

      this.setState({ user, userType, userLocations });
    });
  }

  getUserEmail = () => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return user?.user?.email as string;
  }

  login = (username: string, password: string): Promise<boolean> => {
    return setPersistence(auth, browserLocalPersistence)
    .then(() => {
      return signInWithEmailAndPassword(auth, username, password)
      .then((user) => {
        localStorage.setItem('user', JSON.stringify(user));
        return true;
      })
      .catch((error) => {
        console.log("err", error.message);
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      });
    })
    .catch((error) => {
      console.log(error.message);
      return false;
    })
  }

  logout = () => {
    auth.signOut()
    .then(() => { window.location.href = '/login' })
    .catch(() => alert('Error signing out'));
  }

  render() {
    const { userType, userLocations } = this.state;

    const isLoggedIn = (auth.currentUser !== null);

    return (
      <>
        <div className='mainWrapper'>
          {isLoggedIn && <Sidebar />}
          <div className='rightContainer small'>
            <Routes>
              {
                isLoggedIn
                &&
                <>
                <Route path='/dashboard' element={<Dashboard logout={this.logout} userLocations={userLocations}  />} />
                <Route path='/cumulativeReport' element={<CumulativeReport type={userType} logout={this.logout} userLocations={userLocations} userEmail={this.getUserEmail()} />} />
                <Route path='/performanceAnalysis/:location/:id' element={<PerformanceAnalysis logout={this.logout} />} />
                <Route path='/overallScores' element={<OverallScores logout={this.logout} userLocations={userLocations} userType={userType} userEmail={this.getUserEmail()} />} /> 
                <Route path='/completed' element={<Completed logout={this.logout} userType={userType} userLocations={userLocations} userEmail={this.getUserEmail()} />} /> 
                <Route path='/addUser' element={<AddUser logout={this.logout} />} />
                <Route path='/edit-locations' element={<EditLocations userType={userType} userEmail={this.getUserEmail()} logout={this.logout} />} />
                <Route path='/edit-drivers' element={<EditDrivers userType={userType} userEmail={this.getUserEmail()} logout={this.logout} />} />
                <Route path='/edit-drivers/:dataType/:license/:extraKey/:extraValue' element={<EditSpecificDriver userEmail={this.getUserEmail()} logout={this.logout} userType={userType} />} />
                <Route path='/profile' element={<Profile logout={this.logout} userEmail={this.getUserEmail() } />} /> 
                </>
              }

              <Route path='/login' element={isLoggedIn ? <Navigate replace to={'/dashboard'} /> : <RakshakLogin login={this.login} />} />
              <Route path='*' element={<Navigate replace to={'/login'} />} />
            </Routes>
          </div>
        </div>
      </>
    );
  }
}

export default App;

