import  { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyDNjQ7UtlyueIhpJ_L71bBcbG2VgMA5s-Q",
  authDomain: "smiles-c6bab.firebaseapp.com",
  projectId: "smiles-c6bab",
  storageBucket: "smiles-c6bab.appspot.com",
  messagingSenderId: "260464635566",
  appId: "1:260464635566:web:dc68ad9f14cb4432ba073f",
  measurementId: "G-MP8DZH8VHX"
};

const app = initializeApp(firebaseConfig);
const userCreationApp = initializeApp(firebaseConfig, 'second');
const db = getFirestore(app);
const auth = getAuth(app);
const secondAuth = getAuth(userCreationApp);

export { db, auth, secondAuth };