import { FC, FormEvent, useEffect, useRef, useState } from 'react';
import { UserType } from '../../utils/types';
import { useNavigate, useParams } from 'react-router-dom';
import TopBar from '../TopBar';
import { RRServer } from '../../utils/lib';
import { db } from '../../utils/Firebase';
import { DocumentReference, QueryConstraint, collection, doc, getDocs, query, where } from 'firebase/firestore';

type ESDProps = {
    userType: UserType,
    userEmail: string,
    logout: () => void
};

export const EditSpecificDriver: FC<ESDProps> = ({ logout, userEmail, userType }) => {
    const [data, setData] = useState<{ [key: string]: any }>({});

    const navigate = useNavigate();
    const { dataType, license, extraKey, extraValue } = useParams();

    const docRef = useRef<string>('');

    useEffect(() => {
        if ((userType !== UserType.SUPER_ADMIN) || ((userEmail !== 'test@redchariots.com') && (userEmail !== 'test_iit@redchariots.com'))) {
            navigate('/dashboard');
            return;
        }

        getDriverDetails();
    }, []);

    const getDriverDetails = async () => {
        const collectionRef = collection(db, dataType || '');
        const queryConstraints: QueryConstraint[] = [
            where('License', '==', license),
        ];
        if (extraKey && extraValue && extraKey !== 'rr-empty' && extraValue !== 'rr-empty') {
            queryConstraints.push(where(extraKey, '==', extraValue));
        }
        const q = query(collectionRef, ...queryConstraints);
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((qs) => {
            docRef.current = qs.id;
            setData(qs.data());
        });
    }

    const editDriverDetails = async (ev: FormEvent) => {
        ev.preventDefault();

        const result = await RRServer.updateData({ db, collection: dataType || '', data, doc: docRef.current });

        if (result) {
            alert('Successfully updated data');
        }
        else {
            alert('Failed to update data');
        }
    }

    return (
        <div>
            <TopBar logout={logout} title='Edit Drivers' />
            <div style={{ padding: '3%' }}>
                <form onSubmit={editDriverDetails}>
                    {
                        Object.keys(data).map((key, keyIndex) =>
                            <div className='form-group' key={'formKey' + keyIndex}>
                                <label htmlFor={key}>{key}</label>
                                <input
                                    type='text' className='form-control' style={{ width: '50%' }} id={key} placeholder={key} value={data[key]}
                                    onChange={(ev)=>{
                                        let temp = { ...data };
                                        temp[key] = ev.target.value;
                                        setData(temp);
                                    }}
                                />
                            </div>
                        )
                    }
                    <div style={{ marginTop: '20px' }}>
                        <button className='btn btn-primary' type='submit'>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}