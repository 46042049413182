import { Component } from 'react';
import Footer from '../Footer';
import 'antd/dist/antd.css';
import TopBar from '../TopBar';
import { Select } from 'antd';
import { RRServer } from '../../utils/lib';
import { auth, db, secondAuth } from '../../utils/Firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';

const { Option } = Select;
const fields: any = [
    {
        id: 1, type: "email", grid: "6", name: "email", placeholder: "Email", icon: <>  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
            <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
        </svg></>
    },
    {
        id: 4, type: "password", grid: "6", name: "password", placeholder: "Enter Password", icon: <> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-key" viewBox="0 0 16 16">
            <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z" />
            <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
        </svg></>
    },
    {
        id: 2, type: "select", grid: "6", name: "adminType", placeholder: "Admin Type", icon: <><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
        </svg></>
    },
    {
        id: 3, type: "multiSelect", grid: "6", name: "location", placeholder: "Location Field", icon: <><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-phone" viewBox="0 0 16 16">
            <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
            <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
        </svg></>
    },
    {
        id: 5, type: "select", grid: "6", name: "moduleType", placeholder: "Module Type", icon: <></>
    }
]

interface UserData {
    email: string,
    password: string,
    type: number,
    locations: string[],
    locationOptions: string[],
    isPassword: boolean
    moduleType: string,
}

export default class AddUser extends Component<{ logout: () => void }, UserData> {

    constructor(props: any) {
        super(props);

        this.state = {
            email: '', locations: [], password: '', type: -1, locationOptions: [], isPassword: false, moduleType: ''
        };
    }

    async componentDidMount() {
        const locationData = await RRServer.getData({
            collection: 'locations', db, query: []
        });
        let locationOptions = locationData[0].locations as string[];
        this.setState({ locationOptions });
    }

    onValueChanged = (key: string, value: string | string[]) => {
        if (key === 'email') {
            this.setState({ email: value as string });
        }
        else if (key === 'password') {
            this.setState({ password: value as string });
        }
        else if (key === 'adminType') {
            this.setState({ type: parseInt(value as string) });
        }
        else if (key === 'location') {
            try {
                this.setState({ locations: value as string[] });
            }
            catch {
                this.setState({ locations: [value as string] });
            }
        }
        else if (key === 'moduleType') {
            this.setState({ moduleType: value as string });
        }
    }

    getValueForKey = (key: string): string | string[] => {
        const { email, locations, type, password, moduleType } = this.state;

        if (key === 'email') {
            return email;
        }
        else if (key === 'password') {
            return password;
        }
        else if (key === 'adminType') {
            return type.toString();
        }
        else if (key === 'location') {
            return locations;
        }
        else if (key === 'moduleType') {
            return moduleType;
        }

        return '';
    }

    addUser = async () => {
        const { email, password, type, locations, moduleType } = this.state;

        if (email === '' || password === '' || type === -1 || moduleType === '') {
            return alert('Please fill all necessary fields');
        }

        let data: {[key: string]: any} = {
            email: email.toLowerCase(), locations: (type === 0 ? ['all'] : locations), type
        };
        if (moduleType === 'iit') {
            data.special = true;
        }
        else if (moduleType === 'ap') {
            data.ap = true;
        }

        createUserWithEmailAndPassword(secondAuth, email, password)
            .then(() => {
                this.setState({ email: '', password: '', type: -1, locations: [] });
                return alert('User Created');
            })
            .catch((err) => {
                console.log(err);
                alert('Unable to create user');
            });

        const result = await RRServer.insertData({
            db, collection: 'users', data
        });

        if (!result) {
            alert('There was an error in storing user profile. Please try again!');
        }
    }
    showPassword = (e: any) => {
        this.setState({ isPassword: !this.state.isPassword })
        e.target.previousSibling.type === 'text' ? e.target.previousSibling.setAttribute("type", "password") : e.target.previousSibling.setAttribute("type", "text");
    }

    render() {
        return (
            <>
                <TopBar title="Dashboard" logout={this.props.logout} />

                <div className='welcomeCard'>
                    <div className="info">
                        <p>Welcome Admin</p>
                    </div>
                    <div className="image">
                        {/* <img src="https://download.logo.wine/logo/Indian_Oil_Corporation/Indian_Oil_Corporation-Logo.wine.png" alt="WelcomeCard Image" /> */}
                    </div>
                </div>

                <div className='contentContainer dash'>

                    <div className="addUser">
                        <div className="row roww">
                            {fields.map((field: any, index: number) => {
                                return <div className={`coll col-md-${field.grid} mb-3`} key={'userfields_' + index}>
                                    <div className='field'>
                                        <span className="icon">
                                            {field.icon}
                                        </span>
                                        {
                                            (field.type === "text" || field.type === "email" || field.type === "password")
                                            &&
                                            <>
                                                <input
                                                    type={field.type} name={field.name} placeholder={field.placeholder} className='form-control'
                                                    value={this.getValueForKey(field.name)}
                                                    onChange={(ev) => this.onValueChanged(field.name, ev.target.value)} />
                                                {(field.type === "password")
                                                    &&
                                                    <svg onClick={(e: any) => this.showPassword(e)} style={{ cursor: "pointer", fontSize: "22px", position: "absolute", top: "50%", transform: "translateY(-50%)", right: "30px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                                        {this.state.isPassword ?
                                                            <>
                                                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                                                <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                                            </>
                                                            :

                                                            <>
                                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                            </>}
                                                    </svg>
                                                }

                                            </>
                                        }
                                        {
                                            (field.type === "select") && (field.name === "adminType")
                                            &&
                                            <>
                                                <select
                                                    name={field.name} id={field.name} className='form-select' style={{ width: "100%" }}
                                                    value={this.getValueForKey(field.name)}
                                                    onChange={(ev) => this.onValueChanged(field.name, ev.target.value)}
                                                >
                                                    <option value='-1'>Select Type of user</option>
                                                    <option value='0'>Super Admin</option>
                                                    <option value='1'>Location Admin</option>
                                                </select>
                                            </>
                                        }
                                        {
                                            field.type === "multiSelect"
                                            &&
                                            <>
                                                <Select
                                                    mode="multiple"
                                                    style={{ width: '100%' }}
                                                    placeholder="Select Locations"
                                                    onChange={(ev) => this.onValueChanged(field.name, ev)}
                                                    optionLabelProp="label"
                                                >
                                                    {
                                                        this.state.locationOptions.map((loc, index) =>
                                                            <Option key={index} value={loc} label={loc}>{loc}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </>
                                        }
                                        {
                                            (field.type === "select") && (field.name === "moduleType")
                                            &&
                                            <>
                                                <select
                                                    name={field.name} id={field.name} className='form-select' style={{ width: "100%" }}
                                                    value={this.getValueForKey(field.name)}
                                                    onChange={(ev) => this.onValueChanged(field.name, ev.target.value)}
                                                >
                                                    <option value=''>Select Module Type</option>
                                                    <option value='truck'>Truck</option>
                                                    <option value='iit'>IIT</option>
                                                    <option value='ap'>Asian Paints</option>
                                                </select>
                                            </>
                                        }
                                    </div>
                                </div>
                            })}
                            <button className='btn btn-primary' onClick={this.addUser}>Submit</button>
                        </div>
                    </div>
                    <Footer />
                </div>

            </>
        );
    }
}

