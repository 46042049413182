import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserType } from '../../utils/types';
import { RRServer } from '../../utils/lib';
import { db } from '../../utils/Firebase';
import TopBar from '../TopBar';

type EditLocationsProps = {
    userType: UserType,
    userEmail: string,
    logout: () => void
};

export const EditLocations: FC<EditLocationsProps> = ({ userType, userEmail, logout }) => {
    const navigate = useNavigate();
    const [allLocations, setAllLocations] = useState<string[]>([]);
    const [specialLocations, setSpecialLocations] = useState<string[]>([]);
    const [apLocations, setApLocations] = useState<string[]>([]);
    const [newLocName, setNewLocName] = useState<string>('');
    const [isIITLoc, setIsIITLoc] = useState<boolean>(false);
    const [isApLoc, setIsApLoc] = useState<boolean>(false);

    useEffect(() => {
        if ((userType !== UserType.SUPER_ADMIN) || ((userEmail !== 'test@redchariots.com') && (userEmail !== 'test_iit@redchariots.com'))) {
            navigate('/dashboard');
            return;
        }

        getLocations();
    }, []);

    const getLocations = async () => {
        const specialLocs = await RRServer.getDataById(db, 'locations', 'special-locations') as any;
        setAllLocations((await RRServer.getDataById(db, 'locations', 'locations') as any)['locations'] as string[]);
        setSpecialLocations(specialLocs['bus-locations'] as string[]);
        setApLocations(specialLocs['ap-locations'] as string[]);
    }

    const addNewLocation = async () => {
        if (newLocName === '') {
            return alert('Please fill location name');
        }
        else if (isIITLoc && isApLoc) {
            return alert('Please check only one type of location');
        }

        const temp = [...allLocations];
        temp.push(newLocName);
        let updated = await RRServer.updateData({ db, collection: 'locations', doc: 'locations', data: { locations: temp } });

        if (isIITLoc) {
            const specialTemp = [...specialLocations];
            specialTemp.push(newLocName);
            const specialUpdated = await RRServer.updateData({ db, collection: 'locations', doc: 'special-locations', data: { 'bus-locations': specialTemp } });
            updated = (updated && specialUpdated);
            if (updated) {
                setSpecialLocations(specialTemp);
            }
        }
        if (isApLoc) {
            const apTemp = [...apLocations];
            apTemp.push(newLocName);
            const apUpdated = await RRServer.updateData({ db, collection: 'locations', doc: 'special-locations', data: { 'ap-locations': apTemp } });
            updated = (updated && apUpdated);
            if (updated) {
                setApLocations(apTemp);
            }
        }

        if (updated) {
            setAllLocations(temp);
            alert('Location Added');
            setNewLocName('');
            setIsIITLoc(false);
            setIsApLoc(false);
        }
        else {
            alert('Failed to add new location');
        }
    }

    const deleteLocation = async (name: string) => {
        const newAll = allLocations.filter((val) => val !== name);
        const newSpecial = specialLocations.filter((val) => val !== name);
        const newAp = apLocations.filter((val) => val !== name);

        const updateOne = await RRServer.updateData({ db, collection: 'locations', doc: 'locations', data: { locations: newAll } });
        const updateTwo = await RRServer.updateData({ db, collection: 'locations', doc: 'special-locations', data: { 'bus-locations': newSpecial, 'ap-locations': newAp } });

        if (updateOne && updateTwo) {
            alert('Location ' + name + ' deleted');
            setAllLocations(newAll);
            setSpecialLocations(newSpecial);
            setApLocations(newAp);
        }
        else {
            alert('Unable to fully delete location ' + name);
        }
    }

    return (
        <div>
            <TopBar title="Edit Locations" logout={logout} />

            <h4 style={{ marginLeft: '10px',fontWeight: 'bold' }}>Add new location:</h4>
            <div style={{ marginTop: '10px', marginBottom: '30px', marginLeft: '10px' }}>
                <div className='form-group'>
                    <label htmlFor='locationname'>Location Name:</label>
                    <input type='text' className='form-control' style={{ width: '50%' }} id='locationname' placeholder='Location name' value={newLocName} onChange={(ev)=>setNewLocName(ev.target.value)} />
                </div>
                <div className='form-check'>
                    <input className='form-check-input' type='checkbox' value={''} id='isiit' checked={isIITLoc} onChange={(ev)=>setIsIITLoc(ev.target.checked)} />
                    <label htmlFor='isiit' className='form-check-label'>Is an IIT location</label>
                </div>
                <div className='form-check'>
                    <input className='form-check-input' type='checkbox' value={''} id='ispa' checked={isApLoc} onChange={(ev)=>setIsApLoc(ev.target.checked)} />
                    <label htmlFor='ispa' className='form-check-label'>Is an Asian Paints Location</label>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <button className='btn btn-primary' onClick={addNewLocation}>Submit</button>
                </div>
            </div>

            <h4 style={{ marginLeft: '10px',fontWeight: 'bold' }}>All Locations:</h4>
            <table className='table table-bordered table-hover' style={{ margin: '10px', width: 'calc(100% - 20px)' }}>
                <thead>
                    <tr>
                        <th style={{ verticalAlign: 'middle', textAlign: 'center', fontWeight: 'bold' }}>S No.</th>
                        <th style={{ verticalAlign: 'middle', textAlign: 'center', fontWeight: 'bold' }}>Location Name</th>
                        <th style={{ verticalAlign: 'middle', textAlign: 'center', fontWeight: 'bold' }}>Location Type</th>
                        <th style={{ verticalAlign: 'middle', textAlign: 'center', fontWeight: 'bold' }}>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        allLocations.map((al, alIndex) => 
                            <tr key={'allLoc' + alIndex}>
                                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{alIndex + 1}</td>
                                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{al}</td>
                                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{(specialLocations.findIndex((v) => v === al) !== -1) ? 'IIT' : ((apLocations.findIndex((v) => v === al) !== -1) ? 'Asian Paints' : 'Truck')}</td>
                                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                    <button className='btn btn-danger' onClick={()=>deleteLocation(al)}>Delete</button>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}